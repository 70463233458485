import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import useStay from 'hooks/useStay';
import { getCartItems } from 'store/cart/selectors';
import { faCartPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getTotalItems } from 'utils';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

const faCartPlusIcon = faCartPlus as IconProp;

const ShowCart = ({ settings }) => {
  const products = useSelector(getCartItems);
  const location_tag = useStay();
  const history = useNavigate();

  const handleClick = (e) => {
    e.preventDefault();

    history({
      pathname: `/checkout/${location_tag}`,
    });
  };

  return (
    <div
      className="topHeaderSection__cartContainer"
      style={{ visibility: settings.show_cart ? 'visible' : 'hidden' }}
    >
      <a onClick={handleClick} href="/">
        <FontAwesomeIcon
          icon={faCartPlusIcon}
          className="topHeaderSection__cartContainer__icon"
        />
        {/* // @ts-ignore */}

        {getTotalItems(products) > 0 ? (
          <span
            className="badge topHeaderSection__cartContainer__badge"
            style={{
              background: settings.button_color,
              color: settings.button_text_color,
            }}
          >
            {getTotalItems(products)}
          </span>
        ) : (
          <span
            className="badge topHeaderSection__cartContainer__badge"
            style={{ backgroundColor: '#333' }}
          >
            {getTotalItems(products)}
          </span>
        )}
        <span className="topHeaderSection__cartContainer__text d-md-inline-block">
          {' '}
          CART
        </span>
      </a>
    </div>
  );
};

export default ShowCart;
