export const saveLocal = (localKey: any, localValue: any) => {
  try {
    localStorage.setItem(localKey, localValue);
    return true;
  } catch (e) {
    throw new Error('Unable to save local storage');
  }
};

export const deleteLocal = (localKey) => {
  try {
    localStorage.removeItem(localKey);
    return true;
  } catch (e) {
    throw new Error('Unable to save local storage');
  }
};

export const getStoredLocal = (localKey: string): any => {
  try {
    return localStorage.getItem(localKey) || '';
  } catch (e) {
    throw new Error('Unable to get local storage');
  }
};
