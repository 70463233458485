import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { addToCart } from 'store/cart/actions';
import { formatPrice } from 'utils';
import useLocationCurrency from 'hooks/useLocationCurrency';
import DefaultImage from 'assets/images/default-image.png';
import AddToCart from '../AddToCart';
import parse from 'html-react-parser';
import Gallery from './Gallery';
import { gaAddToCart } from 'utils/google_analytics';
import './styles.scss';

const ShopInventory = ({
  locationTag,
  _id,
  title,
  price,
  description,
  images,
  disabled,
}: any) => {
  const dispatch = useDispatch();
  const { currencyInfo } = useLocationCurrency();
  const symbol =
    currencyInfo?.symbol?.length > 1
      ? `${currencyInfo?.symbol} `
      : currencyInfo?.symbol;

  const [pic] = images;

  const handleAddToCart = () => {
    dispatch(addToCart({ locationTag, item_id: _id, quantity: 1 }));
    toast.success('Added to cart', {
      autoClose: 2000,
      position: 'bottom-right',
    });
    gaAddToCart(title, locationTag, _id);
  };

  return (
    <div className="productSingle row">
      <div className="col-sm-12 col-md-6 details">
        <h3 className="productSingle__name">{title}</h3>
        <h4 className="productSingle__price">
          {symbol}
          {formatPrice(price)}
        </h4>
        <div className="productSingle__description">
          <div className="productSingle__description">
            {' '}
            {parse(description)}
          </div>
        </div>
        <AddToCart
          price={price}
          handleAddToCart={handleAddToCart}
          disabled={disabled}
        />
      </div>

      <div className="col-sm-12 col-md-6 text-center">
        <img
          src={pic || DefaultImage}
          alt="product"
          className="productSingle__image"
        />
        {images?.length > 1 && <Gallery pictures={images} />}
      </div>
    </div>
  );
};

export default ShopInventory;
