import React from 'react';
import { PaymentContext } from 'Context/PaymentContext';
import { formatPrice } from 'utils';

const SubTotal = ({ symbol }: any) => {
  // @ts-ignore
  // test
  const { paymentInformation } = React.useContext(PaymentContext);
  const { total, bnsl_convenience_fee, total_sub, tax } = paymentInformation;

  let charges = tax?.charges || {};
  let account = tax?.account || {};
  let taxes = Object.keys(charges);
  let taxIds = Object.keys(account);

  return (
    <ul className="purchase-item__container" style={{ paddingBottom: 10 }}>
      <>
        <div className="tax_id">
          {taxIds.map((id, i) =>
            account[id] && charges[id] ? (
              <li key={i}>
                {id.toUpperCase()}# {account[id]}
              </li>
            ) : null
          )}
        </div>
      </>
      <li style={{ paddingRight: 30, marginLeft: '-45px' }}>
        <span className="purchase-item__label">Subtotal</span>
        <span className="purchase-item__value">
          {symbol}
          {formatPrice(total_sub)}
        </span>
      </li>
      <>
        {taxes.map((tax, i) => (
          <li key={i} style={{ paddingRight: 30, marginLeft: '-45px' }}>
            <span className="purchase-item__label tax_label">
              {`(${tax.substring(0, 1)})${tax.substring(1)}`}
            </span>

            <span className="purchase-item__value">
              {symbol}
              {formatPrice(parseFloat(charges[tax]))}
            </span>
          </li>
        ))}
      </>
      <li style={{ paddingRight: 30, marginLeft: '-45px' }}>
        <span className="purchase-item__label">BNSL Convenience Fee</span>
        <span className="purchase-item__value">
          {symbol}
          {formatPrice(bnsl_convenience_fee)}
        </span>
      </li>
      <li style={{ paddingRight: 30, marginLeft: '-45px' }}>
        <span className="purchase-item__label">Total</span>
        <span className="purchase-item__value">
          {symbol}
          {formatPrice(parseFloat(total))}
        </span>
      </li>
    </ul>
  );
};

export default SubTotal;
