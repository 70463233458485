// @ts-ignore
import React from 'react';
import { PaymentContext } from 'Context/PaymentContext';

const CustomerInfo = () => {
  // @ts-ignore
  const { paymentInformation } = React.useContext(PaymentContext);
  const { orderNumber, date, name, method } = paymentInformation;
  function capitalize(str) {
    return str?.charAt(0)?.toUpperCase() + str?.slice(1)?.toLowerCase() || ' ';
  }

  return (
    <div className="customer-info">
      <div className="customer-info__box">
        <div>
          <strong>ORDER NUMBER</strong>
          <span>#{orderNumber.toUpperCase()}</span>
        </div>
        <div>
          <strong>DATE</strong>
          <span>{date}</span>
        </div>
      </div>
      <div className="customer-info__box">
        <div>
          <strong>CARDHOLDER NAME</strong>
          <span>{name}</span>
        </div>
        <div>
          <strong>PAYMENT METHOD</strong>
          <span>{capitalize(method)}</span>
        </div>
      </div>
    </div>
  );
};

export default CustomerInfo;
