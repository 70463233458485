import React from 'react';
import './styles.scss';
import { getSettings } from 'store/stays/selectors';
import { useSelector } from 'react-redux';

interface IProps {
  buttonTitle: string;
  onClickHandler: () => void;
}

const UniversalButton = ({ onClickHandler, buttonTitle }: IProps) => {
  const settings = useSelector(getSettings);

  return (
    <button
      type="button"
      className="cartButton"
      onClick={onClickHandler}
      style={{
        color: settings.button_text_color,
        background: settings.button_color,
      }}
    >
      {buttonTitle}
    </button>
  );
};

export default UniversalButton;
