import './styles.scss';

import { BsSearch } from 'react-icons/bs';

const SearchBar = ({ setSearchTerm }) => {
  const handleSearch = (e) => {
    const modifiedSearch = e.target.value.trim().toLowerCase();
    setSearchTerm(modifiedSearch);
  };

  return (
    <div className="search_bar_container">
      <BsSearch className="search_icon" />
      <input type="text" placeholder="Search..." onChange={handleSearch} />
    </div>
  );
};

export default SearchBar;
