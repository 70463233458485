/* eslint no-param-reassign: 0 */
/* eslint dot-notation: 0 */
/* eslint @typescript-eslint/dot-notation: 0 */

import axios from 'axios';
import { CLIENT_KEY, CLIENT_ID, GUEST_TOKEN, API_MAPI_BASE_URL } from 'const/index';

const instance = axios.create({
  baseURL: API_MAPI_BASE_URL,
});

const beforeSend = (config: any) => {
  config.headers.common['Client-Key'] = CLIENT_KEY;
  config.headers.common['Client-ID'] = CLIENT_ID;
  config.headers.common['Authorization'] = `Bearer ${GUEST_TOKEN}`;

  return config;
};

instance.interceptors.request.use(beforeSend);

export default instance;
