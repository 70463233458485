import {
  FETCH_CURRENCIES_REQUEST,
  FETCH_CURRENCIES_SUCCESS,
  FETCH_CURRENCIES_FAILURE,
} from '../actionTypes';
//currencies

export const fetchRequestCurrencies = (): any => {
  return {
    type: FETCH_CURRENCIES_REQUEST,
  };
};

export const fetchCurrenciesSuccess = (payload: any): any => {
  return {
    type: FETCH_CURRENCIES_SUCCESS,
    payload,
  };
};

export const fetchCurrenciesFailure = (payload: any): any => ({
  type: FETCH_CURRENCIES_FAILURE,
  payload,
});
