import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
  fetchGlobalSettingsSuccess,
  fetchGlobalSettingsFailure,
} from '../actions';
import { FETCH_GLOBAL_SETTINGS_REQUEST } from '../actionTypes';
import { getGlobalSettings } from '../api';

function* fetchGlobalSettingsSaga() {
  try {
    const response = yield call(getGlobalSettings);
    yield put(fetchGlobalSettingsSuccess(response?.data?.settings));
  } catch (e: any) {
    yield put(fetchGlobalSettingsFailure(e?.message));
  }
}

function* globalSettingsSaga() {
  yield all([
    takeLatest(FETCH_GLOBAL_SETTINGS_REQUEST, fetchGlobalSettingsSaga),
  ]);
}

export default globalSettingsSaga;
