// @ts-nocheck
import { useParams } from 'react-router-dom';
import { formatPrice } from 'utils/index';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { Col, Card, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { getSettings } from 'store/stays/selectors';
import { CSSTransition } from 'react-transition-group';
import { BsFillInfoCircleFill, BsXCircleFill } from 'react-icons/bs';
import rightArrow from 'assets/svg/right-arrow.svg';
import React, { useState } from 'react';
import {
  getExperiencesSlowLoading,
  getExperiencesCategory,
} from 'store/experiences/selectors';
import {
  gaExperienceButtonClick,
  gaExperienceClick,
} from 'utils/google_analytics';
import './styles.scss';
import CouponCompo from 'shared_components/Coupon';

const Tickets = ({ tickets, demo }) => {
  const settings = useSelector(getSettings);
  const params = useParams();
  const { locationTag } = params;
  const cardPressHandler = (item) => {
    window.open(`${item.product_url}&tq_campaign=${locationTag}`, '_blank');
    if (!demo) {
      gaExperienceClick(item.title, locationTag, item.id);
    }
  };

  const color = settings.custom
    ? settings.button_color
    : settings.nav_background_color;

  return (
    <Row xs={1} sm={2} md={3} lg={4} className="g-4">
      {tickets.map((item, idx) => {
        return (
          <React.Fragment key={idx}>
            <Restaurant item={item} demo={demo} />
            <CustomExperience item={item} demo={demo} />
            <Col style={{ boxShadow: '10px', display: !item.id ? 'none' : '' }}>
              <Card onClick={() => cardPressHandler(item)} className="eventbox">
                <Card.Img
                  variant="top"
                  src={item.images[0] ? item.images[0].large : ''}
                />

                <Card.Body>
                  {/* <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      fontWeight: 'bold',
                    }}
                  >
                    <div
                      style={{
                        color: color,
                        textTransform: 'uppercase',
                        fontSize: 14,
                        paddingBottom: '8px',
                      }}
                    >
                      {item.city_name}
                    </div>
                  </div> */}
                  <div>
                    <Card.Title
                      className="ticket_title"
                      style={{
                        fontWeight: 'bolder',
                        fontSize: 22,
                        color: 'black',
                      }}
                    >
                      {item.title}
                    </Card.Title>
                    <div
                      style={{
                        color: '#222222',
                        fontWeight: '400',
                        fontSize: 16,
                      }}
                    >
                      <div className="ticket_tagline">{item.tagline}</div>
                    </div>
                  </div>
                  <div className="ticket_price_rating">
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'flex-end',
                          color: '#222222',
                        }}
                      >
                        {item?.ratings?.average ? (
                          <div className="rating">
                            <FontAwesomeIcon
                              icon={faStar}
                              style={{
                                fontSize: '16px',
                                marginRight: '4px',
                                color: '#FFBD24',
                                paddingBottom: 6,
                              }}
                            />
                            {item?.ratings?.average} / 5
                            {item?.ratings?.total &&
                              ` (${item?.ratings?.total})`}
                          </div>
                        ) : null}
                      </div>

                      <div>
                        <div
                          style={{
                            display: 'flex',
                            color: '#222222',
                            fontSize: 14,
                          }}
                        >
                          From
                          {item.prediscount_price > 1 && (
                            <div
                              style={{
                                textDecoration: 'line-through',
                                paddingLeft: 4,
                              }}
                            >
                              ${formatPrice(item.prediscount_price)}
                            </div>
                          )}
                        </div>
                        <Card.Text
                          style={{
                            fontWeight: 'bold',
                            fontSize: 24,
                            color: 'black',
                          }}
                        >
                          ${formatPrice(item.price)}
                        </Card.Text>
                      </div>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </React.Fragment>
        );
      })}
      <LoadingSkeleton />
    </Row>
  );
};

export default Tickets;

const LoadingSkeleton = () => {
  const loading = useSelector(getExperiencesSlowLoading);
  const category = useSelector(getExperiencesCategory);
  if (!loading || !['Experiences', 'All'].includes(category)) {
    return;
  }
  return Array(10)
    .fill()
    .map((v, i) => (
      <Col key={i} style={{ boxShadow: '10px' }}>
        <Card className="eventbox skeleton">
          <div className="skeleton-grey skeleton-image" />
          <div className="skeleton-grey skeleton-text" />
          <div className="skeleton-grey skeleton-description" />
        </Card>
      </Col>
    ));
};

const CustomExperience = ({ item, demo }) => {
  const settings = useSelector(getSettings);
  const color = settings.custom
    ? settings.button_color
    : settings.nav_background_color;

  const params = useParams();
  const { locationTag } = params;

  const cardPressHandler = (item) => {
    if (item?.url?.length > 1) {
      window.open(item.url);
    }

    if (!demo) {
      gaExperienceClick(item.title, locationTag, item._id);
    }
  };

  if (!item._id || item.restaurant) {
    return;
  }

  return (
    <Col key={item._id} style={{ boxShadow: '10px' }}>
      <Card onClick={() => cardPressHandler(item)} className="eventbox">
        <Card.Img variant="top" src={item.images[0]} className="custom_img" />
        <Card.Body>
          {item?.coupon_image && item?.coupon_image?.length > 0 && (
            <CouponCompo item={item} onClick={() => cardPressHandler(item)} />
          )}

          <div>
            <Card.Title
              className="ticket_title"
              style={{ fontWeight: 'bolder', fontSize: 22, color: 'black' }}
            >
              {item.title}
            </Card.Title>
            <div style={{ color: '#222222', fontWeight: '400', fontSize: 16 }}>
              <div className="ticket_tagline">{item.description}</div>
            </div>
          </div>
          <div className="ticket_price_rating">
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'flex-end',
                  color: '#222222',
                }}
              >
                {item.rating ? (
                  <div className="rating">
                    <FontAwesomeIcon
                      icon={faStar}
                      style={{
                        fontSize: '16px',
                        marginRight: '4px',
                        color: '#FFBD24',
                        paddingBottom: 6,
                      }}
                    />
                    {item.rating} / 5
                    {item?.total_rating && ` (${item?.total_rating})`}
                  </div>
                ) : null}
              </div>
              <div>
                {item.price > 0 && (
                  <div>
                    <div
                      style={{
                        display: 'flex',
                        color: '#222222',
                        fontSize: 14,
                      }}
                    >
                      From
                    </div>
                    <Card.Text
                      style={{
                        fontWeight: 'bold',
                        fontSize: 24,
                        color: 'black',
                      }}
                    >
                      ${formatPrice(item.price)}
                    </Card.Text>
                  </div>
                )}
              </div>
            </div>
          </div>
        </Card.Body>
      </Card>
    </Col>
  );
};

const Restaurant = ({ item, demo }) => {
  const settings = useSelector(getSettings);
  const color = settings.custom
    ? settings.button_color
    : settings.nav_background_color;

  const [flipped, setFlipped] = useState(false);

  const params = useParams();
  const { locationTag } = params;

  const closeRestaurant = (e) => {
    setFlipped(!flipped);
    e.stopPropagation();
  };

  const InfoPressHandler = () => {
    setFlipped(!flipped);
  };
  const cardPressHandler = (item) => {
    if (!flipped) {
      const firstItemUrl = item?.restaurant_info[0]?.url;
      const firstItemLable = item?.restaurant_info[0]?.label;

      if (firstItemUrl) {
        window.open(firstItemUrl);
        if (!demo) {
          gaExperienceButtonClick(
            item?.title,
            locationTag,
            firstItemLable,
            item._id
          );
        }
      }
    }
  };

  const buttonClick = (info, item) => {
    window.open(info.url);
    if (!demo) {
      gaExperienceButtonClick(item?.title, locationTag, info?.label, item._id);
    }
  };

  if (!item.restaurant) {
    return;
  }

  return (
    <Col key={item._id} style={{ boxShadow: '10px' }}>
      <Card className="eventbox">
        <Card.Img
          variant="top"
          src={item.images[0]}
          className="custom_img"
          onClick={() => cardPressHandler(item)}
        />

        <Card.Body>
          <div>
            {item?.coupon_image && item?.coupon_image?.length > 0 && (
              <CouponCompo item={item} onClick={() => cardPressHandler(item)} />
            )}
            <div>
              <Card.Title
                onClick={() => cardPressHandler(item)}
                className="ticket_title"
                style={{ fontWeight: 'bolder', fontSize: 22, color: 'black' }}
              >
                {item.title}
              </Card.Title>
              <div className="restaurant_card_container_tickets">
                <CSSTransition in={!flipped} timeout={800} classNames="flip">
                  <div className="card">
                    <div
                      className="card-front"
                      onClick={() => cardPressHandler(item)}
                    >
                      <div
                        style={{
                          color: '#222222',
                          fontWeight: '400',
                          fontSize: 16,
                        }}
                      >
                        <div
                          style={{
                            color: '#222222',
                            fontWeight: '400',
                            fontSize: 16,
                          }}
                        >
                          <div className="ticket_tagline">
                            {item.description}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card-back">
                      {item?.restaurant_info?.map(
                        (info, i) =>
                          info?.label &&
                          info?.url && (
                            <div
                              key={i}
                              className="restaurant_button"
                              onClick={() => buttonClick(info, item)}
                            >
                              {info.label}
                            </div>
                          )
                      )}
                    </div>
                  </div>
                </CSSTransition>
              </div>
            </div>
          </div>
          <div className="ticket_price_rating" onClick={InfoPressHandler}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'flex-end',
                  color: '#222222',
                }}
              >
                {item.rating ? (
                  <div className="rating">
                    <FontAwesomeIcon
                      icon={faStar}
                      style={{
                        fontSize: '16px',
                        marginRight: '4px',
                        color: '#FFBD24',
                        paddingBottom: 6,
                      }}
                    />
                    {item.rating} / 5
                    {item?.total_rating && ` (${item?.total_rating})`}
                  </div>
                ) : null}
              </div>

              <div>
                {flipped ? (
                  <button
                    className="flip_restaurant_tickets"
                    onClick={closeRestaurant}
                  >
                    <BsXCircleFill className="flip_icon" />
                  </button>
                ) : (
                  <button
                    className="flip_restaurant_tickets"
                    onClick={InfoPressHandler}
                  >
                    <img src={rightArrow} alt="info" />
                    {/* <BsFillInfoCircleFill className="flip_icon" /> */}
                  </button>
                )}
              </div>
            </div>
          </div>
        </Card.Body>
      </Card>
    </Col>
  );
};
