import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import { Container } from 'react-bootstrap';
import { getStays, getSettings } from 'store/stays/selectors';
import ShowCart from './ShowCart';
import './styles.scss';
import { useEffect } from 'react';

const TopHeaderSection = () => {
  const [scrollPos, setScrollPos] = useState(0);
  const [className, setClassName] = useState('');

  useEffect(() => {
    if (scrollPos >= 40) {
      setClassName('sticky-top');
    } else {
      setClassName('');
    }
  }, [scrollPos]);

  useEffect(() => {
    window.addEventListener('scroll', scroll);
    return () => {
      window.removeEventListener('scroll', scroll);
    };
  });

  const scroll = () => {
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;

    setScrollPos(winScroll);
  };

  const stay = useSelector(getStays);
  const settings = useSelector(getSettings);

  return (
    <div>
      <div className="changeLanguage"> CHANGE LANGUAGE </div>
      <Container>
        <div className={`topHeaderSection ${className}`}>
          {!settings.logo_image && stay && (
            <Link to={`/${stay?.tag}`} className="topHeaderSection__title">
              <h3 translate="no" className="topHeaderSection__title">
                {stay?.name?.toUpperCase() || 'Marketplace'}
              </h3>
            </Link>
          )}
          {settings.logo_image && (
            <Link to={`/${stay?.tag}`} className="topHeaderSection__title">
              <img
                alt="logo"
                src={settings.logo_image}
                className="logo_image"
              />
            </Link>
          )}
          <ShowCart settings={settings} />
        </div>
      </Container>
    </div>
  );
};

export default TopHeaderSection;
