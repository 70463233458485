import {
  FETCH_PRODUCTS_REQUEST,
  FETCH_PRODUCTS_SUCCESS,
  FETCH_PRODUCTS_FAILURE,
} from '../actionTypes';

import {
  IFetchProductsRequestPayload,
  IFetchProductsRequest,
  IFetchProductsSuccessPayload,
  IFetchProductsSuccess,
  IFetchProductsFailurePayload,
  IFetchProductsFailure,
} from '../types';

export const fetchproductsRequest = (
  payload: IFetchProductsRequestPayload
): IFetchProductsRequest => {
  return {
    type: FETCH_PRODUCTS_REQUEST,
    payload,
  };
};

export const fetchProductsSuccess = (
  payload: IFetchProductsSuccessPayload
): IFetchProductsSuccess => ({
  type: FETCH_PRODUCTS_SUCCESS,
  payload,
});

export const fetchProductsFailure = (
  payload: IFetchProductsFailurePayload
): IFetchProductsFailure => ({
  type: FETCH_PRODUCTS_FAILURE,
  payload,
});
