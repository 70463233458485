import {
  FETCH_GLOBAL_SETTINGS_REQUEST,
  FETCH_GLOBAL_SETTINGS_SUCCESS,
  FETCH_GLOBAL_SETTINGS_FAILURE,
} from '../actionTypes';

export const fetchGlobalSettingsRequest = () => {
  return {
    type: FETCH_GLOBAL_SETTINGS_REQUEST,
  };
};

export const fetchGlobalSettingsSuccess = (payload: any): any => {
  return {
    type: FETCH_GLOBAL_SETTINGS_SUCCESS,
    payload,
  };
};

export const fetchGlobalSettingsFailure = (payload: any): any => ({
  type: FETCH_GLOBAL_SETTINGS_FAILURE,
  payload,
});
