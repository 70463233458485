/*eslint-disable */

import {
  FETCH_CURRENCIES_REQUEST,
  FETCH_CURRENCIES_SUCCESS,
  FETCH_CURRENCIES_FAILURE,
} from '../actionTypes';

const initialState = {
  loading: false,
  data: [],
  error: null,
};

const CurrenciesReducer = (state = initialState, action: any): any => {
  switch (action.type) {
    case FETCH_CURRENCIES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_CURRENCIES_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.currencies,
        error: null,
      };
    case FETCH_CURRENCIES_FAILURE:
      return {
        ...state,
        loading: false,
        data: [],
        error: action.payload.error,
      };
    default:
      return {
        ...state,
      };
  }
};

export default CurrenciesReducer;
