export const VERSION = 'v2.1.18';
export const APP_NAME = 'BNBUY_WEB_SHOP';
export const APP_NAME_TOKEN = 'BNBUY_WEB_SHOP_SESS';
export const APP_NAME_CART = 'BNBUY_WEB_SHOP_CART';
export const CLIENT_ID = 'com.bnsellit.app.WEB_SHOP';
export const CLIENT_KEY = 'lBjo0o90a1NddpWOBIgFAHK9M2zww9y47QdKcvkgHNQ';
export const API_AUTH_BASE_URL = 'https://aapi.bnsellit.com:4000/v2';
export const API_MAPI_BASE_URL = 'https://mapi.bnsellit.com:4000/v2';

export const GUEST_TOKEN =
  'a9bb5eaea5f64d81513e80e7fd40a3c627f37049c9fb585d731ddc9aa84349ceb9574c3df0f4440ff4f1544f4fbde939fd4db936cf14a84d';

export const STRIPE_US_PK =
  "pk_live_51J1DHyFEUWjBkis5rga7esXvwCsUDrf4zuVdIPJHCfWl1wjYogvUnXafCTHmjSzys6jUNj3nlGYgGCdpq79VHGzm00BolwBRdv";

export const STRIPE_CA_PK =
  "pk_live_51J0qVVH3vOKRXCsk6fQnmxFsqWiSlw7qXd1ljEn30web5TcYlSD6G2H23mDip9m2MKHZwV83xLseO9d9w5KGeYEv00bRmjsc0N";

export const STRIPE_UE_PK =
  "pk_test_51LaiAgAfRjDsRzg58QwsKxYqZMzPu6D5eI5DDMlTVaLKDxKU5agogMadYZRP1xjWOJpmrUTCW7YkzkV9flIGQWw500oeaUIrqP"
