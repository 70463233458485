import {
  FETCH_CATEGORIES_SUCCESS,
  FETCH_CATEGORIES_FAILURE,
  SET_ACTIVE_CATEGORY,
} from '../actionTypes';

import {
  IFetchCategoriesSuccessPayload,
  IFetchCategoriesSuccess,
  IFetchCategoriesFailurePayload,
  IFetchCategoriesFailure,
  ISetActiveCategoryPayload,
  ISetActiveCategory,
} from '../types';

export const fetchCategoriesSuccess = (
  payload: IFetchCategoriesSuccessPayload
): IFetchCategoriesSuccess => ({
  type: FETCH_CATEGORIES_SUCCESS,
  payload,
});

export const fetchCategoriesFailure = (
  payload: IFetchCategoriesFailurePayload
): IFetchCategoriesFailure => ({
  type: FETCH_CATEGORIES_FAILURE,
  payload,
});

export const setActiveCategory = (
  payload: ISetActiveCategoryPayload
): ISetActiveCategory => ({
  type: SET_ACTIVE_CATEGORY,
  payload,
});
