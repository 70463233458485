import { all, call, put, takeLatest } from 'redux-saga/effects';

import { fetchCurrenciesSuccess } from '../actions';
import { FETCH_CURRENCIES_REQUEST } from '../actionTypes';
import { getCurrencies } from '../api';

function* fetchCurrenciesSaga({ payload }: any) {
  try {
    const response = yield call(getCurrencies);
    yield put(
      fetchCurrenciesSuccess({
        currencies: response.data.currencies,
      })
    );
  } catch (e: any) {
    console.log(e);
  }
}

function* currenciesSaga() {
  yield all([takeLatest(FETCH_CURRENCIES_REQUEST, fetchCurrenciesSaga)]);
}

export default currenciesSaga;
