import React from 'react';
import { Container } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { getProducts } from 'store/products/selectors';
import { getCartItems } from 'store/cart/selectors';
import useLocationCurrency from '../../hooks/useLocationCurrency';
import ProductItem from '../ProductItem';
import './styles.scss';
import { filterRelatedProducts } from 'utils';

const RelatedProducts = ({ item, locationTag }: any) => {
  const products = useSelector(getProducts);
  const cartProducts = useSelector(getCartItems);
  const { currencyInfo } = useLocationCurrency();

  const filteredProducts = filterRelatedProducts(products, item, cartProducts);

  return filteredProducts.length ? (
    <Container>
      <div className="relatedProducts">
        <h3 className="relatedProducts__title_header">
          RECOMMENDATIONS FOR YOU
        </h3>
        <ul className="row">
          {filteredProducts.map((item: any) => (
            <ProductItem
              key={item._id}
              {...item}
              locationTag={locationTag}
              currencyInfo={currencyInfo}
            />
          ))}
        </ul>
      </div>
    </Container>
  ) : null;
};

export default RelatedProducts;
