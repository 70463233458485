import { Container } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import useStay from 'hooks/useStay';
import { useState, useEffect } from 'react';
import { filterProducts, filterQuantityCheck } from 'utils';
import { getProducts, getProductsLoading } from 'store/products/selectors';
import { getCatSelected } from 'store/categories/selectors';
import { getSettings, getStays } from 'store/stays/selectors';
import { getCartItems } from 'store/cart/selectors';
import ProductItem from '../ProductItem';
import useLocationCurrency from '../../hooks/useLocationCurrency';
import Banner from '../Banner';
import SearchBar from '../SearchBar';

import './styles.scss';

interface IProps {
  locationTag: string;
}

const hiddenIds = [
  '6304dc91138bb52f23b00a39',
  '6304dd6c1ce2b3dbe521d161',
  '6304de051ce2b3dbe521d17a',
  '6304de521ce2b3dbe521d18e',
  '6304ded9138bb52f23b00ad4',
  '6304df2c6acb0cfb927a0432',
];

const filterHidden = (products, selectCat) => {
  if (selectCat?.name === 'All') {
    return products.filter((product) => !hiddenIds.includes(product._id));
  }
  return products;
};

const ProductList = ({ locationTag }: IProps) => {
  const history = useNavigate();
  const products = useSelector(getProducts);
  const cartProducts = useSelector(getCartItems);
  const settings = useSelector(getSettings);
  const stay = useSelector(getStays);
  const loading = useSelector(getProductsLoading);
  const selectCat = useSelector(getCatSelected);
  const location_tag = useStay();
  const { currencyInfo } = useLocationCurrency();
  const [searchTerm, setSearchTerm] = useState('');
  const [searchProducts, setSearchProducts] = useState([]);

  // @ts-ignore
  let filteredData = filterProducts(products, selectCat);
  filteredData = filterQuantityCheck(filteredData, cartProducts);
  filteredData = filterHidden(filteredData, selectCat);

  useEffect(() => {
    const searchResults = filteredData.filter((product) => {
      return (
        product.title.trim().toLowerCase().includes(searchTerm) ||
        product.category.trim().toLowerCase().includes(searchTerm)
      );
    });

    setSearchProducts(searchResults);
  }, [searchTerm]);

  // @ts-ignore
  const renderItems = () => {
    return (
      <ul className="row prod-container">
        {searchTerm
          ? searchProducts.map((item: any) => {
              return (
                <ProductItem
                  key={item._id}
                  {...item}
                  locationTag={locationTag}
                  currencyInfo={currencyInfo}
                />
              );
            })
          : filteredData.map((item: any) => {
              return (
                <ProductItem
                  key={item._id}
                  {...item}
                  locationTag={locationTag}
                  currencyInfo={currencyInfo}
                />
              );
            })}
      </ul>
    );
  };
  if (loading) return <p className="text-center">loading.. </p>;

  if (filteredData.length < 1 && location_tag) {
    if (cartProducts.length > 0) {
      history({
        pathname: `/checkout/${location_tag}`,
      });
    }

    if (cartProducts.length <= 0) {
      history({
        pathname: `/${location_tag}/concierge`,
      });
    }
  }

  if (!products || products.length === 0)
    return <p className="empty__products">No products to show </p>;

  return (
    <Container>
      {!filteredData || filteredData.length === 0 ? (
        <p className="empty__products">No results found.</p>
      ) : (
        <div>
          {stay && settings.logo_image && settings.show_location_name && (
            <div translate="no" className="location_name">
              <p> {stay?.name?.toUpperCase()}</p>
            </div>
          )}

          {settings.show_banner && <Banner />}

          {settings.show_search_bar && (
            <SearchBar setSearchTerm={setSearchTerm} />
          )}

          {renderItems()}
        </div>
      )}
    </Container>
  );
};

export default ProductList;
