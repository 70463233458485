// @ts-nocheck

import React, { useState, useContext } from 'react';
import moment from 'moment';
import { PaymentContext } from 'Context/PaymentContext';
import { toast } from 'react-toastify';
import valid from 'card-validator';
import { useDispatch, useSelector } from 'react-redux';
import { emptyCart } from 'store/cart/actions';
import { useFormik } from 'formik';
import axios from 'axiosInstance';
import { getCartItems } from 'store/cart/selectors';
import { useParams } from 'react-router-dom';
import {
  getSubTotal,
  formatPrice,
  getBnslConvenienceFee,
  getTaxTotal,
} from 'utils';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import './styles.scss';
import { getSettings } from 'store/stays/selectors';
import { getGlobalSettings } from 'store/globalSettings/selectors';

const FormPayment = () => {
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();
  const { setPaymentInformation, setSuccessPayment } =
    useContext(PaymentContext);
  const [loading, setLoading] = useState(false);
  const products = useSelector(getCartItems);
  const [error, setError] = useState(null);
  let { locationTag } = useParams();
  const settings = useSelector(getSettings);

  const globalSettings = useSelector(getGlobalSettings);

  const iframeStyles = {
    base: {
      display: 'flex',
      color: 'black',
      fontSize: '16px',
      iconColor: 'black',
      '::placeholder': {
        color: 'gray',
      },
    },
    invalid: {
      iconColor: 'red',
      color: 'red',
    },
    complete: {
      iconColor: '#cbf4c9',
    },
  };

  const cardElementOpts = {
    iconStyle: 'solid',
    style: iframeStyles,
    hidePostalCode: true,
  };
  const sendPayment = async (payload: any) => {
    setLoading(true);

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      console.log('Stripe.js has not yet loaded.');
      return;
    }
    let location_tag = null;
    const items = products.map((item: any) => {
      location_tag = item?.location_tag;
      return { id: item._id, qty: item.quantity };
    });
    const total = formatPrice(
      getSubTotal(products) +
        getBnslConvenienceFee(getSubTotal(products), globalSettings) +
        getTaxTotal(products)
    );
    // @ts-ignore
    try {
      const data = await axios.post(`/locations/${location_tag}/payment`, {
        total: total,
      });
      const { client_secret } = data?.data;
      const { error: stripeError, paymentIntent } =
        await stripe.confirmCardPayment(client_secret, {
          payment_method: {
            card: elements.getElement(CardElement),
            billing_details: {
              name: payload?.card_name,
              email: payload?.email,
            },
          },
        });

      if (stripeError) {
        // Show error to your customer (e.g., insufficient funds)
        const error = new Error(stripeError?.message || 'Something went Wrong');
        throw error;
      }
      const resp = await axios.post(`/locations/${location_tag}/transaction`, {
        id: paymentIntent.id,
        items,
      });
      setLoading(false);
      if (resp.data) {
        return resp.data.transaction;
      }
    } catch (err) {
      setLoading(false);
      toast.error('Sorry Unable to send payment this time', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      return false;
    }
  };

  const validateEmail = (email: any) => {
    let re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const formik = useFormik({
    initialValues: {
      cardNumber: '',
      cardExpiration: '',
      cardSecurityCode: '',
      cardName: '',
      email: '',
      card: '',
    },
    validate: (values) => {
      const errors = {};
      let creditCard = valid.number(values.cardNumber);
      creditCard.cardExpiration = valid.expirationDate(values.cardExpiration);
      creditCard.cardSecurityCode = valid.cvv(values.cardSecurityCode);
      creditCard.cardName = valid.cardholderName(values.cardName);

      if (!values.cardName) {
        errors.cardName = 'Card name is required';
      } else if (!creditCard.cardName.isValid) {
        errors.cardName = 'Invalid card name';
      }
      if (!validateEmail(values.email)) {
        errors.email = 'Email is Invalid';
      }
      if (error) {
        errors.card = 'Please Fill the Card Info';
      }

      return errors;
    },
    onSubmit: async (values) => {
      const payload = {
        card_num: values.cardNumber,
        card_cvv: values.cardSecurityCode,
        card_expiry: values.cardExpiration,
        email: values.email.trim(),
        card_name: values.cardName,
      };

      const rs = await sendPayment(payload);
      if (rs) {
        const successpayload = getSuccessPayload(rs);
        setPaymentInformation(successpayload);
        setSuccessPayment(true);
        setLoading(false);
        formik.resetForm();
        dispatch(emptyCart({ locationTag }));
      }
    },
  });

  const getErrors = (errors: any, touched: any) => {
    const ErrorKeys = Object.keys(errors);
    const hasErrors = [];

    for (let key in errors) {
      if (errors[key] && touched[key]) {
        hasErrors.push(key);
      }
    }

    if (errors && ErrorKeys.length > 0) {
      return (
        <>
          {hasErrors.length > 0 && (
            <p className="error-title">Please fix the following errors: </p>
          )}

          <ul>
            {ErrorKeys.filter((k) => {
              return touched[k];
            }).map((key, i) => {
              return (
                <li key={i} className="error-list">
                  {errors[key]}
                </li>
              );
            })}
          </ul>
        </>
      );
    }
    return null;
  };

  const getSuccessPayload = (resp: any) => {
    const {
      number,
      created_at,
      total_net,
      payment_method,
      payment_card_name,
      total,
      bnsl_convenience_fee,
      total_sub,
      tax,
    } = resp;
    const newProducts = products.map((item) => {
      return {
        image: item?.images[0],
        price: item.price,
        name: item.title,
        quantity: item.quantity ? item.quantity : item.qty,
        tax: item.tax,
      };
    });
    return {
      orderNumber: number,
      date: moment(created_at).format('MMMM DD YYYY'),
      name: payment_card_name,
      method: payment_method,
      products: newProducts,
      total,
      sub: total_net,
      bnsl_convenience_fee,
      total_sub: total_sub,
      tax,
    };
  };

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="formPayment">
          {getErrors(formik.errors, formik.touched)}
          <div className="formPayment__col">
            <label>Email</label>
            <input
              type="text"
              name="email"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.email}
              autoComplete="off"
            />
          </div>
          <div className="formPayment__col">
            <label>Name on Card</label>
            <input
              type="text"
              name="cardName"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.cardName}
              autoComplete="off"
            />
          </div>

          <CardElement
            id="card"
            options={cardElementOpts}
            onChange={(e) => setError(e.error)}
          />

          <div className="formPayment__col">
            <button
              disabled={loading}
              className="formPayment__paynow"
              type="submit"
              style={{
                color: settings.button_text_color,
                background: settings.button_color,
              }}
            >
              {loading ? 'payment proccessing...' : 'Pay Now'}
            </button>
          </div>
        </div>
      </form>
      {loading && (
        <div className="backdrop">
          <div className="loader" />
        </div>
      )}
    </>
  );
};

export default FormPayment;
