import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Footer from 'components/Footer';
import Banner from 'components/Banner';
import Tickets from 'components/Tickets/index';
import Viator from 'components/viator/index';
import Categories from 'components/Categories';
import Header from './Header';
import './styles.scss';
import { useDispatch } from 'react-redux';
import { getSettings } from 'store/stays/selectors';
import ExperienceSearch from 'components/ExperienceSearch';
import axios from '../../axiosInstance';
import {
  getExperiences,
  getExperiencesLoading,
} from 'store/experiences/selectors';
import { fetchExperiencesRequest } from 'store/experiences/actions';
import { getProvider } from 'store/experiences/experience_utils';
import { getStaysLoading, getStays } from 'store/stays/selectors';

const CampaignDemoPage = () => {
  const dispatch = useDispatch();
  const [provider, setProvider] = useState('');
  const settings = useSelector(getSettings);
  const { campaignTag } = useParams();
  const loading = useSelector(getStaysLoading);
  const response = useSelector(getStays);
  const [campaign, setCampaign] = useState({
    _id: 0,
  });

  const experiences = useSelector(getExperiences);
  const experiencesLoading = useSelector(getExperiencesLoading);

  const experienceProps = {
    experiences: [...experiences]
      .sort((a, b) => (a._id === campaign?._id ? -1 : 1))
      .filter((exp) => !exp.hidden),
    demo: true,
  };

  useEffect(() => {
    const asyncFunc = async () => {
      try {
        const res = await axios.get(`/campaign/${campaignTag}/demo`);
        setCampaign(res?.data?.campaign);
        await dispatch({
          type: 'FETCH_STAYS_REQUEST',
          payload: {
            location_tag: res?.data?.campaign?.location_tag,
          },
        });
      } catch (err) {
        console.log(err);
      }
    };
    asyncFunc();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (response?.tag) {
      dispatch(
        fetchExperiencesRequest({
          ...response,
          demo: true,
          campaignTag,
        })
      );
      setProvider(getProvider(response));
    }
  }, [response, campaignTag, dispatch]);

  useEffect(() => {
    if (response?.name) {
      document.title = `${response?.name} Experiences`;
    }
  }, [response]);

  if (loading) {
    return (
      <div
        style={{
          position: 'fixed',
          top: '50%',
          left: '50%',
        }}
        className="spinner-border"
        role="status"
      />
    );
  }

  return (
    <div className="concierge-page">
      <Header />

      <div className="container-xl" style={{ minHeight: '100vh' }}>
        {settings.logo_image && settings.show_location_name && (
          <div translate="no" className="location_name">
            <p> {response?.name?.toUpperCase()}</p>
          </div>
        )}
        {settings.show_banner && (
          <Banner hideExploreBtn={true} locationInfo={response} />
        )}

        {settings.show_categories && experiences?.length ? (
          <Categories experiences={experiences} />
        ) : null}

        {settings.show_experience_search && <ExperienceSearch />}

        {experiencesLoading ? (
          <div className="backdrop">
            <div className="loader" />
          </div>
        ) : (
          <div>
            {provider === 'tiqets' ? (
              <Tickets {...experienceProps} demo />
            ) : (
              <Viator {...experienceProps} demo />
            )}
          </div>
        )}
      </div>

      <Footer />
    </div>
  );
};

export default CampaignDemoPage;
