// @ts-nocheck

import TicketList from './TicketList';
import { useSelector } from 'react-redux';
import { getExperiencesSlowLoading } from 'store/experiences/selectors';
import { Col, Card, Row } from 'react-bootstrap';

const Tickets = ({ experiences, demo }) => {
  const loading = useSelector(getExperiencesSlowLoading);

  if (loading && !experiences?.length) {
    return (
      <Row xs={1} sm={2} md={3} lg={4} className="g-4">
        {Array(15)
          .fill()
          .map((v, i) => (
            <Col key={i} style={{ boxShadow: '10px' }}>
              <Card className="eventbox skeleton">
                <div className="skeleton-grey skeleton-image" />
                <div className="skeleton-grey skeleton-text" />
                <div className="skeleton-grey skeleton-description" />
              </Card>
            </Col>
          ))}
      </Row>
    );
  }

  return (
    <div style={{ paddingBottom: 40 }}>
      {experiences && experiences.length ? (
        <TicketList tickets={experiences} demo={demo} />
      ) : (
        <div>No events found near you.</div>
      )}
    </div>
  );
};

export default Tickets;
