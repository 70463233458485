import { useEffect } from 'react';
import ProductSinglePageContainer from 'containers/ProductSinglePageContainer';
import './styles.scss';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

const ProductSinglePage = () => {
  const dispatch = useDispatch();
  let { locationTag } = useParams();

  useEffect(() => {
    dispatch({
      type: 'FETCH_STAYS_REQUEST',
      payload: {
        location_tag: locationTag,
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return <ProductSinglePageContainer />;
};

export default ProductSinglePage;
