import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateCart } from '../../store/cart/actions';
import { getCartItems } from 'store/cart/selectors';
import {
  getSubTotal,
  formatPrice,
  getBnslConvenienceFee,
  getTaxTotal,
} from 'utils';
import CartItem from './CartItem';
import './styles.scss';
import Taxes from './Taxes';
import { getProducts } from 'store/products/selectors';
import { getGlobalSettings } from 'store/globalSettings/selectors';
import useLocationCurrency from 'hooks/useLocationCurrency';
interface IProps {
  editableQuantity: boolean;
  locationTag?: string;
  setDeleteLoading?: any;
}

const CartList = ({
  editableQuantity,
  locationTag,
  setDeleteLoading,
}: IProps) => {
  const { currencyInfo } = useLocationCurrency();

  const dispatch = useDispatch();
  const inventory = useSelector(getProducts);
  const symbol =
    currencyInfo?.symbol?.length > 1
      ? `${currencyInfo?.symbol} `
      : currencyInfo?.symbol;
  useEffect(() => {
    dispatch(updateCart());
  }, [dispatch, inventory]);

  const products = useSelector(getCartItems);

  const globalSettings = useSelector(getGlobalSettings);

  if (products.length === 0)
    return (
      <div
        className="mt-3  text-center container alert alert-primary"
        role="alert"
      >
        Your shopping cart is empty!
      </div>
    );

  const hasTax = !!products.find(
    (product) => Object.keys(product.tax?.rates || [])?.length
  );

  return (
    <div className="cart_list clearfix">
      <div className="cart_list__column-labels clearfix price_tax">
        {hasTax && <p className="price_tax__tax">TAX</p>}
        <p className="price_tax__price">PRICE</p>
      </div>
      {products.map((item: any) => (
        <CartItem
          setDeleteLoading={setDeleteLoading}
          key={item._id}
          item_id={item._id}
          quantity={item.quantity}
          {...item}
          locationTag={locationTag}
          editableQuantity={editableQuantity}
          tax={item.tax}
          hasTax={hasTax}
          symbol={symbol}
        />
      ))}

      <div className="formPayment_container">
        <div className="formPayment__amount-to-pay">
          <label>Subtotal</label>
          <div>
            {symbol}
            {formatPrice(getSubTotal(products))}
          </div>
          <div className="line" />
        </div>

        <Taxes products={products} symbol={symbol} />

        <div className="formPayment__amount-to-pay">
          <label>BNSL Convenience Fee</label>
          <div>
            {symbol}
            {formatPrice(
              getBnslConvenienceFee(getSubTotal(products), globalSettings)
            )}
          </div>
          <div className="line" />
        </div>

        <div
          className="formPayment__amount-to-pay"
          style={{ fontWeight: 'bold' }}
        >
          <label>Total Due</label>
          <div>
            {symbol}
            {formatPrice(
              getSubTotal(products) +
                getBnslConvenienceFee(getSubTotal(products), globalSettings) +
                getTaxTotal(products)
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CartList;
