import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setExperiencesSearch } from 'store/experiences/actions';
import SearchBar from 'components/SearchBar';
import './styles.scss';

const ExperienceSearch = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setExperiencesSearch(searchTerm));
  }, [searchTerm, dispatch]);

  return (
    <div className="experience_search_bar">
      <SearchBar setSearchTerm={setSearchTerm} />
    </div>
  );
};

export default ExperienceSearch;
