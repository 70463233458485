import { all, fork } from 'redux-saga/effects';
import productsSaga from './products/sagas';
import experiencesSaga from './experiences/sagas';
import globalSettingsSaga from './globalSettings/sagas';
import currencies from './currencies/sagas';
import cartSaga from './cart/sagas';
import authSaga from './auth/sagas';
import staysSaga from './stays/sagas';

export function* rootSaga() {
  yield all([
    fork(experiencesSaga),
    fork(currencies),
    fork(productsSaga),
    fork(cartSaga),
    fork(authSaga),
    fork(staysSaga),
    fork(globalSettingsSaga),
  ]);
}
