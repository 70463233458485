import React from 'react';
import Icon from 'assets/images/icons/bnsell_icon.svg';
import Button from 'components/common/Button';

import './styles.scss';

const NotFoundPage = () => {
  return (
    <div className="not-found-page">
      <div className="not-found-page__container">
        <div className="not-found-page__col">
          <img src={Icon} alt="logo" />
          <h3>404 NOT FOUND</h3>
          <p>
            SORRY, THE MARKETPLACE YOU ARE LOOKING FOR IS NOT OPEN FOR BUSINESS
            YET.
          </p>
          <Button
            text="GO TO HOMEPAGE"
            type="link"
            variant="primary"
            url="/"
            extraClassName="btn-rdr-home"
          />
        </div>
      </div>
    </div>
  );
};

export default NotFoundPage;
