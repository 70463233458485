import React from 'react';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Link } from 'react-router-dom';

const faArrowLeftIcon = faArrowLeft as IconProp;

interface IProps {
  link: string;
}

const BackButton = ({ link }: IProps) => {
  return (
    <div className="container">
      <Link to={link} style={{ textDecoration: 'none', color: '#000' }}>
        <FontAwesomeIcon
          icon={faArrowLeftIcon}
          style={{ fontSize: '16px', marginRight: '12px' }}
        />
        <span style={{ fontSize: '16px' }}>Back</span>
      </Link>
    </div>
  );
};

export default BackButton;
