import React from 'react';
import { useSelector } from 'react-redux';
import { getProducts } from 'store/products/selectors';
import { filterProducts } from 'utils';
import { getCatSelected } from 'store/categories/selectors';
import ImageAndroid from 'assets/images/android.png';
import ImageIos from 'assets/images/ios.png';
import './styles.scss';

const AppBox = () => {
  const products = useSelector(getProducts);
  const catSelected = useSelector(getCatSelected);
  const prod = filterProducts(products, catSelected);
  const { name: category_name } = catSelected;

  if (
    category_name.toLocaleLowerCase() !== 'rental' ||
    !prod ||
    prod.length === 0
  )
    return null;
  /* eslint-disable react/jsx-no-target-blank */
  return (
    <div className="App-box">
      <p>
        To rent the listed items from this host you need to download the BnBuyit
        App.
      </p>
      <div className="App-box__app-container">
        <a href="https://apps.apple.com/us/app/id1526893588" target="_blank">
          <img src={ImageIos} alt="android" />
        </a>
        <a
          href="https://play.google.com/store/apps/details?id=app.bnsellitinc.bnbuyit"
          target="_blank"
        >
          <img src={ImageAndroid} alt="ios" />
        </a>
      </div>
    </div>
  );
};

export default AppBox;
