/*eslint-disable */

import { saveToDBLocal } from '../sagas';
import {
  ADD_TO_CART_SUCCESS,
  REMOVE_FROM_CART_SUCCESS,
  ADD_QUANTITY,
  SUB_QUANTITY,
  EMPTY_CART,
} from '../actionTypes';

import { CartActions, ICartState, IProductCart } from '../types';

const initialState: ICartState = {
  addedItems: [],
};

const CartReducer = (state = initialState, action: CartActions) => {
  switch (action.type) {
    case ADD_TO_CART_SUCCESS:
    case REMOVE_FROM_CART_SUCCESS:
      return {
        ...state,
        addedItems: action.payload.addedItems,
      };
    case ADD_QUANTITY: {
      const newCart = {
        ...state,
        addedItems: state.addedItems.map((item: IProductCart) => {
          return item.item_id === action.payload.item_id
            ? { ...item, quantity: item.quantity + 1 }
            : item;
        }),
      };
      const [item] = newCart.addedItems;
      saveToDBLocal(item.location_tag, newCart.addedItems);

      return newCart;
    }

    case SUB_QUANTITY: {
      const newCart2 = {
        ...state,
        addedItems: state.addedItems.map((item: IProductCart) => {
          return item.item_id === action.payload.item_id
            ? {
                ...item,
                quantity: item.quantity - 1 === 0 ? 1 : item.quantity - 1,
              }
            : item;
        }),
      };

      const [item1] = newCart2.addedItems;

      saveToDBLocal(item1.location_tag, newCart2.addedItems);

      return newCart2;
    }

    case EMPTY_CART:
      // empty the cache
      saveToDBLocal(action.payload.locationTag, []);
      return {
        ...state,
        addedItems: [],
      };

    default:
      return state;
  }
};

export default CartReducer;
