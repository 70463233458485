import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setExperiencesCategory } from 'store/experiences/actions';
import {
  getExperiencesCategory,
  getExperiencesSlowLoading,
} from 'store/experiences/selectors';
import { getSettings, getStays } from 'store/stays/selectors';
import { getExperienceCategories } from '../../store/experiences/experience_utils';
import styled from 'styled-components';

const Root = styled.div`
  display: flex;
  margin-bottom: 20px;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: -10px;
`;

const Button = styled.div`
  background: ${(props) => (props.selected ? props.button_color : '#e1e1e1')};
  color: ${(props) => (props.selected ? props.button_text_color : 'black')};
  font-weight: ${(props) => props.selected && '600'};
  box-shadow: ${(props) =>
    props.selected &&
    'rgba(0, 0, 0, 0.2) 0px 5px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset'};
  cursor: pointer;
  padding: 5px 12px;
  border-radius: 10px;
  margin: 0px 5px;
  margin-top: 10px;
`;

const Categories = ({ experiences }: any) => {
  const selected = useSelector(getExperiencesCategory);
  const dispatch = useDispatch();
  const settings = useSelector(getSettings);
  const location = useSelector(getStays);

  const categories = getExperienceCategories(experiences);
  const slowLoading = useSelector(getExperiencesSlowLoading);

  useEffect(() => {
    if (categories.includes(location?.default_category)) {
      dispatch(setExperiencesCategory(location.default_category));
    }
    //eslint-disable-next-line
  }, [dispatch]);

  useEffect(() => {
    if (!slowLoading) {
      dispatch(setExperiencesCategory(selected));
    }
    //eslint-disable-next-line
  }, [slowLoading, dispatch]);

  const handleSelectCategory = (category) => {
    dispatch(setExperiencesCategory(category));
  };

  return (
    <Root className="categories-container">
      {categories.map((cat, i) => (
        <Button
          key={i}
          onClick={() => handleSelectCategory(cat)}
          selected={cat === selected}
          button_color={settings.button_color}
          button_text_color={settings.button_text_color}
        >
          {cat}
        </Button>
      ))}
    </Root>
  );
};

export default Categories;
