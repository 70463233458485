import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { PaymentContext } from 'Context/PaymentContext';
import useQueryProducts from 'hooks/useQueryProducts';
import useCart from 'hooks/useCart';
import { Container } from 'react-bootstrap';
import ProductBaseLayout from 'components/Layout/ProductBaseLayout';
import OrderCheckout from 'components/OrderCheckout';
import OrderSummary from 'components/OrderSummary';

const CheckoutPageContainer = () => {
  const [paymentInformation, setPaymentInformation] = useState({});
  const [successPayment, setSuccessPayment] = useState(false);
  let { locationTag } = useParams();

  useQueryProducts(locationTag || '');
  useCart(locationTag || '');

  return (
    <PaymentContext.Provider
      value={{
        paymentInformation,
        setPaymentInformation,
        successPayment,
        setSuccessPayment,
      }}
    >
      <ProductBaseLayout>
        <div className="checkout-page">
          <Container>
            {successPayment ? (
              <OrderSummary locationTag={locationTag} />
            ) : (
              <OrderCheckout locationTag={locationTag} />
            )}
          </Container>
        </div>
      </ProductBaseLayout>
    </PaymentContext.Provider>
  );
};

export default CheckoutPageContainer;
