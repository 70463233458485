import React from 'react';
import Header from '../Header';
import Footer from '../Footer';
import './styles.scss';

interface IProps {
  children: React.ReactNode;
  hideFilter?: boolean;
}

const ProductBaseLayout = ({ children, hideFilter }: IProps) => {
  return (
    <div>
      <Header hideFilter={hideFilter} />
      <div className="main-content">{children}</div>
      <Footer />
    </div>
  );
};

export default ProductBaseLayout;
