import { all, call, put, takeLatest, select } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { fetchProductsSuccess, fetchProductsFailure } from '../actions';
import { FETCH_PRODUCTS_REQUEST } from '../actionTypes';
import { getProductsByStayId } from '../api';
import { IFetchProductsRequest } from '../types';
import { ffilterForQuantity, isEqual } from 'utils';
import {
  fetchCategoriesSuccess,
  fetchCategoriesFailure,
} from '../../categories/actions';

function* fetchProductsSaga({ payload }: IFetchProductsRequest) {
  const { setLoading, history, location_tag, path } = payload;
  try {
    const auth = yield select((state) => state.auth.data);
    const response = yield call(getProductsByStayId, location_tag, auth);
    let prod = response.data.inventory;
    prod = ffilterForQuantity(prod);
    const categories = getCategories(prod);
    try {
      yield put(
        fetchCategoriesSuccess({
          categories: categories,
        })
      );
    } catch (e: any) {
      yield put(
        fetchCategoriesFailure({
          error: e.message,
        })
      );
    }

    if (
      prod.length === 0 ||
      (isEqual(location_tag, 'np89gv') && !path.includes('/product')) ||
      (isEqual(location_tag, '5iehaa') && !path.includes('/product')) ||
      (isEqual(location_tag, '6jgug5') && !path.includes('/product'))
    ) {
      yield put(
        fetchProductsSuccess({
          products: [],
        })
      );
      history(`/${location_tag}/concierge`);
      return;
    }
    yield put(
      fetchProductsSuccess({
        products: prod,
      })
    );
    if (typeof setLoading !== 'undefined') {
      setLoading(false);
      history(`/${location_tag}`);
    }
  } catch (e: any) {
    if (typeof setLoading !== 'undefined') {
      setLoading(false);
    }
    toast.error('Cant find this host');
    yield put(
      fetchProductsFailure({
        error: e.message,
      })
    );
  }
}

function* productsSaga() {
  yield all([takeLatest(FETCH_PRODUCTS_REQUEST, fetchProductsSaga)]);
}
interface ICategories {
  name: String;
  _id: String;
}
export default productsSaga;
const getCategories = (products) => {
  const tempData = [];
  const tempDataObje = [];

  products.map((item) => {
    // @ts-ignore
    if (item?.status === 'ACTIVE') {
      const temp = item?.category;

      // @ts-ignore
      if (!tempData.includes(temp)) {
        // @ts-ignore
        tempData.push(temp);
        // @ts-ignore
        tempDataObje.push({ name: temp, _id: temp });
      }
    }
  });

  return tempDataObje;
};
