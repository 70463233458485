import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import Icon from 'assets/images/icons/bnsell_icon.svg';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { fetchproductsRequest } from 'store/products/actions';
import './styles.scss';

const HostForm = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const history = useNavigate();
  const [host, setHost] = useState('');
  const path = window.location.href;

  const handleSubmit = () => {
    setLoading(true);
    if (!host) {
      toast.error('Please enter a host');
      setLoading(false);
    } else if (host.length <= 3) {
      setLoading(false);
      toast.error('Enter a valid host');
    } else {
      dispatch(
        fetchproductsRequest({
          location_tag: host,
          history,
          setLoading,
          path,
        })
      );
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setHost(value);
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleSubmit();
    }
  };

  return (
    <div className="form__submit__products__form">
      <img
        src={Icon}
        alt="logo"
        className="form__submit__products__form__logo"
      />
      <input
        type="text"
        name="host"
        className="form__submit__products__form__host"
        onChange={handleChange}
        placeholder="Enter Host ID"
        onKeyPress={handleKeyPress}
        disabled={loading}
      />
    </div>
  );
};

export default HostForm;
