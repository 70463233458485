/*eslint-disable */

import {
  FETCH_EXPERIENCES_REQUEST,
  FETCH_EXPERIENCES_SUCCESS,
  FETCH_EXPERIENCES_FAILURE,
  SET_EXPERIENCES_CATEGORY,
  SET_EXPERIENCES_SEARCH,
} from '../actionTypes';

const initialState = {
  loading: true,
  data: [],
  error: null,
  category: 'All',
  search: '',
  slowLoading: true,
};

const experiencesReducer = (state = initialState, action: any): any => {
  switch (action.type) {
    case FETCH_EXPERIENCES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_EXPERIENCES_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        ...(!action.payload.filtered && {
          slowLoading: !!action.payload.slowLoading,
        }),
      };
    case FETCH_EXPERIENCES_FAILURE:
      return {
        ...state,
        loading: false,
        slowLoading: false,
        data: action.payload.data,
        error: action.payload.error,
      };
    case SET_EXPERIENCES_CATEGORY:
      return {
        ...state,
        category: action.payload,
      };
    case SET_EXPERIENCES_SEARCH:
      return {
        ...state,
        search: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};

export default experiencesReducer;
