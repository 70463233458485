/*eslint-disable */

import {
  AUTHENTICATE_USER,
  AUTHENTICATE_USER_SUCCESS,
  AUTHENTICATE_USER_FAILURE,
} from '../actionTypes';

import { IUserState, ProductActions } from '../types';

const initialState: IUserState = {
  loading: false,
  data: {},
  error: null,
};

const AuthReducer = (
  state = initialState,
  action: ProductActions 
): IUserState => {
  switch (action.type) {
    case AUTHENTICATE_USER:
      return {
        ...state,
        loading: true,
      };
    case AUTHENTICATE_USER_SUCCESS:
      return {
        ...state,
        error: null,
        data: action.payload.user,
      };
    case AUTHENTICATE_USER_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        data: {},
      };
    default:
      return {
        ...state,
      };
  }
};

export default AuthReducer;
