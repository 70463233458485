// @ts-nocheck
import { destinations } from './destinationIds';
import { API_MAPI_BASE_URL } from 'const/index';

import * as viator from './filter_viator';

export const getNearestLngAndLat = (lat, lng) => {
  const lon1 = lng;
  const lat1 = lat;
  const R = 6371e3; // metres

  const area = destinations.filter(function (item) {
    const lon2 = item.longitude;
    const lat2 = item.latitude;
    const φ1 = (lat1 * Math.PI) / 180; // φ, λ in radians
    const φ2 = (lat2 * Math.PI) / 180;
    const Δφ = ((lat2 - lat1) * Math.PI) / 180;
    const Δλ = ((lon2 - lon1) * Math.PI) / 180;

    const a =
      Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
      Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    const d_meters = R * c; // in metres
    // distance in milles
    const d_miles = d_meters * 0.000621371192;
    /* eslint-disable */
    item.url = `${API_MAPI_BASE_URL}/aff/viator?destination=${item.destinationId}&currency=${item.defaultCurrencyCode}`;

    return d_miles > 0 && d_miles < 50;
  });
  return area;
};

export const sortCustom = (data) => {
  if (!data || !data?.length) {
    return [];
  }
  const compare = (a, b) => {
    if (a.position < b.position) {
      return -1;
    }
    if (a.position > b.position) {
      return 1;
    }
    return 0;
  };

  return data.sort(compare);
};

export const getAddress = (address: any) => {
  const { line1, city, state, country } = address;
  const places: any = [];

  if (line1) {
    places.push(line1);
  }
  if (city) {
    places.push(city);
  }
  if (state) {
    places.push(state);
  }
  if (country) {
    places.push(country);
  }

  if (places.length > 0) {
    return places.join(', ');
  }

  return '';
};

export const getProvider = (location) => {
  const providers = location.providers;
  let provider = '';
  if (Array.isArray(providers)) {
    provider = providers[0] || 'tiqets';
  } else if (typeof providers === 'object') {
    provider = 'newviator';
  }

  return provider;
};

export const filterViator = ({
  data,
  destination,
  location_tag,
  hiddenIds,
}) => {
  let filteredData = data.filter((item) =>
    viator.filter(item, destination?.destination_id, location_tag)
  );

  filteredData = filteredData.filter(
    (data) => !hiddenIds.includes(data?.productCode)
  );

  const uniqueProducts = filteredData.reduce((acc, curr) => {
    if (!acc.find((item) => item.productCode === curr.productCode)) {
      acc.push(curr);
    }
    return acc;
  }, []);

  return uniqueProducts;
};
export const filterTickets = (tickets, location, hiddenIds) => {
  if (!tickets || !tickets?.length) {
    return [];
  }
  let sortedTickets = [];
  if (tickets) {
    sortedTickets = tickets.sort(function (a, b) {
      var keyA = new Date(a.distance),
        keyB = new Date(b.distance);

      // Compare the 2 dates
      if (keyA < keyB) return -1;
      if (keyA > keyB) return 1;
      return 0;
    });
  }

  if (location?.address?.country === 'CA') {
    sortedTickets = sortedTickets.filter(
      (ticket) => ticket.country_name !== 'United States'
    );
  }

  const filteredTickets = sortedTickets.filter((ticket) => {
    const saleStatus = ticket.sale_status === 'available';
    const showTicket = !hiddenIds.includes(ticket.id);
    return saleStatus && showTicket;
  });

  return filteredTickets;
};

export const sortByKeyWord = (location, data) => {
  let tempKeywordArray = [];
  const temp = [];
  const viator_search_keyword =
    location?.providers?.viator?.viator_search_keyword;
  data.map((item) => {
    if (
      item?.title.includes(viator_search_keyword) ||
      item?.description.includes(viator_search_keyword)
    ) {
      tempKeywordArray.push(item);
    }
    if (
      !item?.title.includes(viator_search_keyword) &&
      !item?.description.includes(viator_search_keyword)
    ) {
      temp.push(item);
    }
  });

  const finalArray = tempKeywordArray.concat(temp);
  return finalArray;
};

export const getExperienceCategories = (experiences) => {
  const order = [
    'All',
    'Experiences',
    'Dining',
    'Cafes',
    'Spas / Fitness',
    'Services',
  ];

  let filtered = experiences
    .filter((exp) => !!exp._id && !!exp.category)
    .map((exp) => exp.category);

  let category = [].concat(...filtered).filter((cat) => !!cat);

  let categories = [
    // @ts-ignore
    ...new Set(['All', 'Experiences', ...category]),
  ];

  categories.forEach((cat) => {
    if (!order.includes(cat)) {
      order.push(cat);
    }
  });

  return categories.sort((a, b) => order.indexOf(a) - order.indexOf(b));
};
