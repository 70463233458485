import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { fetchproductsRequest } from 'store/products/actions';
import { useNavigate } from 'react-router-dom';

const useQueryProducts = (locationTag: string) => {
  const history = useNavigate();
  const dispatch = useDispatch();
  const path = window.location.href;
  useEffect(() => {
    dispatch(
      fetchproductsRequest({
        location_tag: locationTag,
        history,
        path,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default useQueryProducts;
