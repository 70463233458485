import { all, call, put, takeLatest } from 'redux-saga/effects';
import { fetchStaysSuccess } from '../actions';
import { FETCH_STAYS_REQUEST } from '../actionTypes';
import { getStay } from '../api';

function* fetchStaysSaga({ payload }: any) {
  const { location_tag } = payload;
  try {
    const response = yield call(getStay, location_tag);

    yield put(
      fetchStaysSuccess({
        stays: response.data.location,
      })
    );
  } catch (e: any) {
    console.log(e);
  }
}

function* staysSaga() {
  yield all([takeLatest(FETCH_STAYS_REQUEST, fetchStaysSaga)]);
}

export default staysSaga;
