import { useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import NotFoundPage from 'pages/NotFoundPage';
import HomePage from './pages/HomePage';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import ConciergePage from './pages/ConciergePage';
import StayLocationPage from './pages/StayLocationPage';
import ProductSinglePage from './pages/ProductSinglePage';
import { fetchRequestCurrencies } from 'store/currencies/actions';
import { fetchGlobalSettingsRequest } from 'store/globalSettings/actions';
import CheckoutPage from './pages/CheckoutPage';
import CampaignDemoPage from './pages/CampaignDemoPage';
import { getStays } from 'store/stays/selectors';
import './sass/app.scss';

function App() {
  const dispatch = useDispatch();

  const location = useSelector(getStays);

  useEffect(() => {
    dispatch(fetchRequestCurrencies());
    dispatch(fetchGlobalSettingsRequest());
    // testing the code
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (location?.name) {
      document.title = location?.name;
    }
  }, [location]);

  return (
    <BrowserRouter basename="/">
      <Routes>
        {/* <Route path="/" element={<Navigate to="/" />} /> */}
        <Route path="/" element={<HomePage />} />
        <Route
          path="/product/:itemId/:locationTag"
          element={<ProductSinglePage />}
        />
        <Route path="/:campaignTag/demo" element={<CampaignDemoPage />} />
        <Route path="/checkout/:locationTag" element={<CheckoutPage />} />
        <Route path="/:locationTag" element={<StayLocationPage />} />
        <Route path="/:locationTag/concierge" element={<ConciergePage />} />
        <Route element={<NotFoundPage />} />
      </Routes>
      <ToastContainer />
    </BrowserRouter>
  );
}

export default App;
