import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import useCart from 'hooks/useCart';
import useQueryProducts from 'hooks/useQueryProducts';
import ProductBaseLayout from 'components/Layout/ProductBaseLayout';
import { Container } from 'react-bootstrap';
import { getProducts, getProductsLoading } from 'store/products/selectors';
import BackButton from 'components/BackButton';
import ProductSingle from '../components/ProductSingle';
import RelatedProducts from '../components/RelatedProducts';
import { getCartItems } from 'store/cart/selectors';
import { getStaysLoading, getStays } from 'store/stays/selectors';

const ProductSinglePage = () => {
  let { locationTag } = useParams();
  useCart(locationTag || '');
  useQueryProducts(locationTag || '');

  let { itemId } = useParams();
  const products = useSelector(getProducts);
  const loading = useSelector(getProductsLoading);
  const stayLoading = useSelector(getStaysLoading);
  const cartProducts = useSelector(getCartItems);
  const location = useSelector(getStays);

  const item = products.find((product) => product._id === itemId);

  // @ts-ignore
  let itemInCart = cartProducts.find((cartItem) => cartItem?._id === item?._id);

  // @ts-ignore
  let disabled = itemInCart?.quantity >= itemInCart?.qty;

  useEffect(() => {
    if (item?.title && location?.name) {
      document.title = `${item?.title} | ${location?.name}`;
    }
  }, [item, location]);

  if (loading || stayLoading) {
    return (
      <div
        style={{
          position: 'fixed',
          top: '50%',
          left: '50%',
        }}
        className="spinner-border"
        role="status"
      />
    );
  }

  return (
    <ProductBaseLayout>
      <div className="product-single">
        <Container className="main-container">
          {item ? (
            <>
              <BackButton link={`/${locationTag}`} />
              <div className="product-single__content-area">
                <ProductSingle
                  {...item}
                  locationTag={locationTag}
                  disabled={disabled}
                />
              </div>
              <RelatedProducts item={item} locationTag={locationTag} />
            </>
          ) : (
            <p className="no-product">Product does not exist.</p>
          )}
        </Container>
      </div>
    </ProductBaseLayout>
  );
};

export default ProductSinglePage;
