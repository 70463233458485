import { useSelector } from 'react-redux';
import TopHeaderSection from 'components/TopHeaderSection';
import Navigation from 'components/Navigation';
import './styles.scss';
import { getSettings } from 'store/stays/selectors';

interface IProps {
  hideFilter?: boolean;
}
/* eslint-disable no-unneeded-ternary */
// const hide = getStoredLocal('BNBUY_DOWNLOAD') === 'hide' ? false : true;

const Header = (props: IProps) => {
  const { hideFilter = true } = props;
  const settings = useSelector(getSettings);

  return (
    <div className="header_section">
      <div className="top-header-wrapper">
        <TopHeaderSection />
      </div>
      {!hideFilter && (
        <div
          style={{
            paddingTop: 77,
            backgroundColor: settings.show_navbar
              ? settings.nav_background_color
              : '',
            paddingBottom: settings.show_navbar ? 0 : 15,
          }}
        >
          {settings.show_navbar && (
            <Navigation navTextColor={settings.nav_text_color} />
          )}
        </div>
      )}
    </div>
  );
};

export default Header;
