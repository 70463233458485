import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { APP_NAME_CART } from 'const/index';
import { addToCartSuccess } from 'store/cart/actions';
import { getCartItems } from 'utils';

const useCart = (locationTag: string) => {
  const dispatch = useDispatch();
  const CART_ITEMS = getCartItems(APP_NAME_CART);

  useEffect(() => {
    if (CART_ITEMS) {
      const currentCart = CART_ITEMS.find(
        (item: any) => item.locationTag === locationTag
      );

      if (currentCart)
        dispatch(addToCartSuccess({ addedItems: currentCart.cart }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default useCart;
