// @ts-nocheck

import React from 'react';
// import { LightBox } from 'react-lightbox-pack';
// import 'react-lightbox-pack/dist/index.css';
import './styles.scss';

const Gallery = ({ pictures }: any) => {
  const [toggle, setToggle] = React.useState(false);
  const [sIndex, setSIndex] = React.useState(0);

  const lightBoxHandler = (state, sIndex) => {
    setToggle(state);
    setSIndex(sIndex);
  };

  let data = pictures.map((item: any, i: number) => {
    return {
      id: Math.random() * 1000,
      image: item,
      title: `image${i}`,
    };
  });

  /* eslint-disable   jsx-a11y/click-events-have-key-events */
  /* eslint-disable   jsx-a11y/no-noninteractive-element-interactions */
  /* eslint-disable   jsx-a11y/no-static-element-interactions */
  return (
    <div>
      <div className="thumb-gallery">
        {data.map((item, index) => (
          <div
            key={item.id}
            className="thumb-gallery__image-box"
            style={{ backgroundImage: `url('${item.image}'` }}
            onClick={() => {
              lightBoxHandler(true, index);
            }}
          />
        ))}
      </div>
     
      {/* <LightBox
        state={toggle}
        event={lightBoxHandler}
        data={data}
        imageWidth="60vw"
        imageHeight="70vh"
        thumbnailHeight={50}
        thumbnailWidth={50}
        setImageIndex={setSIndex}
        imageIndex={sIndex}
      /> */}
    </div>
  );
};

export default Gallery;
