import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { addToCart } from 'store/cart/actions';
import { toast } from 'react-toastify';
import { formatPrice } from 'utils';
import DefaultImage from 'assets/images/default-image.png';
import ConciergeButton from 'components/conciergeButton';
import AddToCart from '../AddToCart';
import RequestFormButton from '../RequestButton';
import { gaAddToCart, gaViewInventory } from 'utils/google_analytics';

import './styles.scss';

interface IProps {
  locationTag: string;
  _id: string;
  images: string[];
  title: string;
  price: number;
  category: string;
  type: string;
  screen: boolean;
  disabled: boolean;
  meta: any;
  currencyInfo: any;
}

const ProductItem = (props: IProps) => {
  const history = useNavigate();
  const {
    locationTag,
    category,
    _id: item_id,
    images,
    title,
    price,
    type,
    disabled,
    currencyInfo,
  } = props;

  const notRental = category.toLocaleLowerCase() !== 'rental';
  const shopInventory = type.toLocaleLowerCase() === 'shop';
  const conciergeInventory = type.toLocaleLowerCase() === 'concierge';
  const serviceInventory = type.toLocaleLowerCase() === 'service';

  const dispatch = useDispatch();
  const [image] = images;
  const symbol =
    currencyInfo?.symbol?.length > 1
      ? `${currencyInfo?.symbol} `
      : currencyInfo?.symbol;

  const handleAddToCart = () => {
    dispatch(addToCart({ locationTag, item_id, quantity: 1 }));
    toast.success('Added to cart', {
      position: toast.POSITION.BOTTOM_RIGHT,
      className: 'foo-bar',
      autoClose: 2000,
    });
    gaAddToCart(title, locationTag, item_id);
  };
  const handleRequestForm = () => {
    window.scrollTo(0, 0);
    history(`/product/${item_id}/${locationTag}`);
    gaViewInventory(title, locationTag, 'service', item_id);
  };

  const handleConcierge = () => {
    window.scrollTo(0, 0);
    history(`/product/${item_id}/${locationTag}`);
    gaViewInventory(title, locationTag, 'concierge', item_id);
  };

  const handleProductClick = () => {
    window.scrollTo(0, 0);
    gaViewInventory(title, locationTag, type.toLocaleLowerCase(), item_id);
  };

  return (
    <li
      className={`col-6 col-md-4 col-lg-3 product ${
        notRental ? '' : 'no-cart'
      }`}
    >
      <Link
        to={`/product/${item_id}/${locationTag}`}
        role="menuitem"
        onKeyDown={() => window.scrollTo(0, 0)}
        onClick={handleProductClick}
        className="product__show"
      >
        <img
          src={image || DefaultImage}
          alt="product"
          className="product__image"
        />
        <h3 className="product__title">{title}</h3>
        {shopInventory && (
          <h4 className="product__price">
            {symbol}
            {formatPrice(price)}
          </h4>
        )}
      </Link>

      {shopInventory && (
        <AddToCart
          price={price}
          handleAddToCart={handleAddToCart}
          disabled={disabled}
        />
      )}
      {serviceInventory && (
        <RequestFormButton handleRequestForm={handleRequestForm} />
      )}
      {conciergeInventory && (
        <ConciergeButton handleConcierge={handleConcierge} />
      )}
    </li>
  );
};

export default ProductItem;
