import { useState, useEffect } from 'react';
import DefaultImage from 'assets/images/default-image.png';
import UniversalButton from 'components/UniversalButton';
import Gallery from './Gallery';
import { gaConciergeClick } from 'utils/google_analytics';
import './styles.scss';

const ConciergeInventory = ({
  title,
  description,
  images,
  meta,
  locationTag,
  _id,
}: any) => {
  const [frameHeight, setFrameHeight] = useState(
    description?.includes('iframe') ? undefined : 20
  );
  const [pic] = images;

  useEffect(() => {
    const frame = document.getElementById('myFrame');

    setFrameHeight(description?.includes('iframe') ? undefined : 20);

    setTimeout(() => {
      setFrameHeight(
        /* @ts-ignore */
        frame?.contentWindow?.document?.body?.scrollHeight + 20 || 0 + 'px'
      );
    }, 1000);
  }, [description]);

  const handleConciergeClick = (url, phone, label) => {
    if (phone) {
      window.open(`tel:${url}`);
    } else {
      window.open(url, '_blank');
    }
    gaConciergeClick(title, locationTag, label, _id);
  };

  return (
    <div className="productSingle row">
      <div className="col-sm-12 col-md-6 details ">
        <h3 className="productSingle__name">{title}</h3>

        <div className="productSingle__description">
          <div
            className="productSingle__description"
            style={{ height: frameHeight }}
          >
            <iframe
              width="100%"
              className="myFrame"
              id="myFrame"
              marginHeight={0}
              marginWidth={0}
              scrolling="no"
              height={frameHeight}
              srcDoc={`<div style="font-family: Montserrat,sans-serif;font-size: 16px;font-weight: 400;color: #434343;">${description}</div>`}
            />
          </div>
        </div>
        {meta && (
          <div className="twoButtons">
            {meta?.links &&
              meta.links.map((item) => {
                return (
                  item.url && (
                    <div className="twoButtons_uni_button">
                      {' '}
                      <UniversalButton
                        buttonTitle={item.label}
                        onClickHandler={() =>
                          handleConciergeClick(item.url, false, item.label)
                        }
                      />
                    </div>
                  )
                );
              })}
            {meta.book_now_url && (
              <div className="twoButtons_uni_button">
                {' '}
                <UniversalButton
                  buttonTitle="Reservations"
                  onClickHandler={() =>
                    handleConciergeClick(
                      meta.book_now_url,
                      false,
                      'Reservations'
                    )
                  }
                />
              </div>
            )}
            {meta.call_now && (
              <div className="twoButtons_uni_button">
                {' '}
                <UniversalButton
                  buttonTitle="Call Now"
                  onClickHandler={() =>
                    handleConciergeClick(meta.call_now, true, 'Call Now')
                  }
                />
              </div>
            )}
          </div>
        )}
      </div>
      <div className="col-sm-12 col-md-6 text-center">
        <img
          src={pic || DefaultImage}
          alt="product"
          className="productSingle__image"
        />
        {images?.length > 1 && <Gallery pictures={images} />}
      </div>
    </div>
  );
};

export default ConciergeInventory;
