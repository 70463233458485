const dataNotToShow = [
  {
    viatorLocationid:
      '320728P17,320728P16,8653P5797,320728P15,8653P6647,8653P6260,8653P6266,8653P6661,8653P5793,128285P1829,8653P5789,320728P14,320728P14,8653P6268,8653P6664,128285P1820,128285P1819,128285P1822,128285P1821,128285P1827,128285P1828,128285P1826,228697P1,5423RSWAPTHTL_P,128285P1245,5423RSWHTLAPT_P,128285P1243',
    destination_id: '5196',
  },
  // {
  //   viatorLocationid: "188339P3,155682P3,64829P5,76258P4,277002P1,170061P2,38075P26,76258P1,32251P9,38075P114,32251P1,32251P2,188339P2,32251P4,54503P4,188339P1,32251P6,293665P1,32251P5,76258P6,64741P7,277288P1,284504P1,76258P3,76258P2",
  //   destination_id: "22381"
  // },
  {
    viatorLocationid: '3348ORLBG_ARC',
    destination_id: 666,
  },
];
export const filter = (data, destination_id, location_tag) => {
  let show = true;
  dataNotToShow.map((item) => {
    if (
      item?.viatorLocationid.includes(data?.productCode) &&
      item?.destination_id == destination_id
    ) {
      show = false;
    }
  });

  const hiddenKeyWords = ['airport', 'private transfer', 'transportation'];

  hiddenKeyWords.map((keyword) => {
    if (
      data?.description?.toLowerCase().includes(keyword) ||
      data?.title?.toLowerCase().includes(keyword)
    ) {
      show = false;
    }
  });
  //hide location experiences
  const locationKeywords = [
    { locationId: '1y8st8', keyWord: 'airboat' },
    { locationId: '4uam7p', keyWord: 'airboat' },
  ];
  locationKeywords.map((item) => {
    if (
      (data?.description?.toLowerCase().includes(item.keyWord) &&
        item.locationId == location_tag) ||
      (data?.title?.toLowerCase().includes(item.keyWord) &&
        item.locationId == location_tag)
    ) {
      show = false;
    }
  });

  return show;
};
