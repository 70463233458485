import React from 'react';
import { Container } from 'react-bootstrap';
import ImageFooter from 'assets/images/powered_by_metaguest.png';

import './styles.scss';

const TopFooter = () => {
  return (
    <div className="top-footer">
      <Container className="top-footer__wrapper">
        <a href="https://metaguest.ai/" target="_blank">
          <img src={ImageFooter} alt="footer" />
        </a>
      </Container>
      <div className="message">
        <a
          target="_blank"
          href="https://thecse.com/en/listings/technology/metaguestai-incorporated"
        >
          Metaguest is a publicly traded company on the CSE under METG
        </a>
      </div>
    </div>
  );
};

export default TopFooter;
