import React from 'react';
import './styles.scss';
import { getSettings } from 'store/stays/selectors';
import { useSelector } from 'react-redux';

interface IProps {
  handleRequestForm: () => void;
}

const AddToCart = ({ handleRequestForm }: IProps) => {
  const settings = useSelector(getSettings);

  return (
    <button
      type="button"
      className="cartButton"
      onClick={handleRequestForm}
      style={{
        color: settings.button_text_color,
        background: settings.button_color,
      }}
    >
      Request
    </button>
  );
};

export default AddToCart;
