import './styles.scss';
const CouponCompo = ({ item, onClick }) => {
  return (
    <div class="coupon" onClick={onClick}>
      <img src={item?.coupon_image[0]} alt="logo" />
    </div>
  );
};

export default CouponCompo;
