import { useState, useEffect } from 'react';
import { fetchStaysSuccess } from 'store/stays/actions';
import axios from 'axiosInstance';
import { useDispatch } from 'react-redux';

const useAxios = (locationTag: string) => {
  const [response, setResponse] = useState(null);
  const [error, setError] = useState('');
  const [loading, setloading] = useState(true);
  const dispatch = useDispatch();

  const fetchData = () => {
    axios
      .get(`/locations/tag/${locationTag}`)
      .then((res) => {
        setResponse(res.data?.location);
        dispatch(fetchStaysSuccess({ stays: res.data.location }));
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        setloading(false);
      });
  };

  useEffect(() => {
    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationTag]);

  // custom hook returns value
  return { response, error, loading };
};

export default useAxios;
