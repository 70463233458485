import {
  FETCH_STAYS_REQUEST,
  FETCH_STAYS_SUCCESS,
  FETCH_STAYS_FAILURE,
} from '../actionTypes';

export const fetchStaysRequest = (payload: any): any => {
  return {
    type: FETCH_STAYS_REQUEST,
    payload,
  };
};

export const fetchStaysSuccess = (payload: any): any => {
  return {
    type: FETCH_STAYS_SUCCESS,
    payload,
  };
};

export const fetchProductsFailure = (payload: any): any => ({
  type: FETCH_STAYS_FAILURE,
  payload,
});
