import React from 'react';
import { Link } from 'react-router-dom';
import './styles.scss';

interface IProps {
  text: string;
  type: 'link' | 'button';
  variant: string;
  url?: string;
  extraClassName?: string;
  buttonType?: 'button' | 'submit' | 'reset' | undefined;
  clickHandler?: () => void;
}

const Button = ({
  text,
  type,
  variant,
  url ,
  extraClassName,
  buttonType,
  clickHandler,
}: IProps) => {
  if (type === 'link') {
    return (
 
      <Link to={url as string}  className={`btn__${variant} ${extraClassName}`}>
        {text}
      </Link>
    );
  }

  return (
    <button
      /* eslint-disable react/button-has-type */
      type={buttonType}
      className={`${variant} ${extraClassName}`}
      onClick={clickHandler}
    >
      {text}
    </button>
  );
};

export default Button;
