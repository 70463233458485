/* eslint-disable max-len */
import React, { useEffect, useState, useRef } from 'react';
import './styles.scss';
import { useSelector, useDispatch } from 'react-redux';
import Slider from 'react-slick';
import { getCategories, getCatSelected } from 'store/categories/selectors';
import { setActiveCategory } from 'store/categories/actions';
import { TCategory } from 'types/TCategories';
import { getProducts } from 'store/products/selectors';
import { getCartItems } from 'store/cart/selectors';
import { filterCategories } from 'utils';

const NextArrow = (props) => {
  // @ts-ignore
  const { className, style, onClick } = props;

  return (
    <div
      className={className}
      style={{
        ...style,
        position: 'static',
        height: '20px',
        zIndex: 999,
        width: '40px',
        float: 'right',
      }}
      onClick={onClick}
      role="button"
    />
  );
};

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      role="button"
      // @ts-ignore
      onKeyDown={onclick}
      className={className}
      style={{
        ...style,
        position: 'static',
        height: '20px',
        zIndex: 999,
        width: '40px',
        float: 'left',
      }}
      onClick={onClick}
    />
  );
}
const Navigation = ({ navTextColor }) => {
  const products = useSelector(getProducts);

  // filter for category
  const defaultCategory: TCategory = {
    _id: 'all',
    name: 'All',
  };

  const categories = useSelector(getCategories);
  let selectedCat = useSelector(getCatSelected);
  let cartItems = useSelector(getCartItems);
  const [collapse, setcollapse] = useState(false);

  selectedCat =
    Object.keys(selectedCat).length === 0 ? defaultCategory : selectedCat;

  const dispatch = useDispatch();
  const handleSelectCat = (cat: TCategory) => {
    dispatch(setActiveCategory({ category: cat }));
  };

  const handleCollapse = (e) => {
    if (e) e.preventDefault();
    setcollapse((prev) => !prev);
  };

  const handleSelectCategory = (item: TCategory) => {
    let allowCategoryToclick: boolean = true;
    cartItems.map((cartItems) => {
      if (item.name == cartItems.category) {
        products.map((productItems) => {
          if (productItems._id === cartItems._id) {
            // @ts-ignore
            if (productItems.qty <= cartItems.qty) {
              allowCategoryToclick = false;
            }
          }
        });
      }
    });

    if (item._id === 'all') {
      handleSelectCat(defaultCategory);
      return;
    }
    // @ts-ignore
    const _cat = categories.find((list) => list._id === item._id);
    handleSelectCat(_cat!);
  };
  useEffect(() => {
    handleSelectCat(defaultCategory);
  }, [0]);

  const getSlidesToShow = (maxSlides, max = 800) => {
    let characters = 0;
    let slides = 1;

    categories.forEach((category) => {
      let maxRow = Math.ceil(max / slides);
      characters += category.name.length;

      while (slides > 1 && characters > maxRow) {
        let newMax = Math.ceil(max / slides);

        if (characters <= newMax) break;
        slides -= 1;
      }
      if (characters <= maxRow) {
        slides += 1;
      }
    });

    return slides > maxSlides ? maxSlides : slides;
  };

  const slider = useRef(null);
  const renderMobile = () => {
    const settings = {
      infinite: false,
      dots: false,
      speed: 500,
      // @ts-ignore
      slidesToShow: getSlidesToShow(10),
      slidesToScroll: getSlidesToShow(10),
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 1800,
          settings: {
            slidesToShow: getSlidesToShow(8, 700),
            slidesToScroll: getSlidesToShow(8, 700),
          },
        },
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: getSlidesToShow(7, 500),
            slidesToScroll: getSlidesToShow(7, 500),
          },
        },
        {
          breakpoint: 950,
          settings: {
            slidesToShow: getSlidesToShow(6, 350),
            slidesToScroll: getSlidesToShow(6, 350),
          },
        },
        {
          breakpoint: 550,
          settings: {
            slidesToShow: getSlidesToShow(3, 350),
            slidesToScroll: getSlidesToShow(3, 350),
          },
        },
        {
          breakpoint: 400,
          settings: {
            slidesToShow: getSlidesToShow(3, 238),
            slidesToScroll: getSlidesToShow(3, 238),
          },
        },
      ],

      customPaging: (i) => <p> </p>,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
    };

    const filteredCategories = filterCategories(categories);

    return categories.length ? (
      <ul
        style={{
          paddingLeft: 0,
        }}
      >
        <Slider ref={slider} {...settings}>
          {[defaultCategory, ...filteredCategories].map(
            (item: TCategory, index) => {
              const tempData = item?.name?.toLowerCase();
              // @ts-ignore
              return (
                <li
                  key={item._id}
                  className={
                    selectedCat.name === item.name
                      ? 'nav_link active'
                      : 'nav_link'
                  }
                >
                  <a
                    href="/"
                    onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
                      e.preventDefault();
                      handleSelectCategory(item);
                      handleCollapse(null);
                    }}
                    style={{ color: navTextColor }}
                  >
                    {item.name}
                  </a>
                </li>
              );
            }
          )}
        </Slider>
      </ul>
    ) : null;
  };
  return <div> {renderMobile()}</div>;
};

export default Navigation;
