import { IProductCart } from 'store/cart/types';
import { APP_NAME_TOKEN, APP_NAME_CART } from 'const/index';
import { getStoredLocal, saveLocal } from 'utils/localstorage';

export const filterProducts = (p: any, selectCat: any) => {
  if (selectCat._id === 'all') return p;
  return p.filter((item: any) => {
    return item.category.toLowerCase() === selectCat.name.toLowerCase();
  });
};
const services = ['service', 'concierge'];

export const filterCategories = (categories: any) => {
  const uniqueCategoryNames = new Set();

  return categories.filter((category) => {
    const isDuplicate = uniqueCategoryNames.has(category.name.toLowerCase());

    uniqueCategoryNames.add(category.name.toLowerCase());

    if (!isDuplicate) {
      return true;
    }

    return false;
  });
};

export const filterQuantityCheck = (filteredData: any, cartProducts: any) => {
  return filteredData.filter((item) => {
    let removeItem = false;
    cartProducts.map((cartProduct) => {
      if (item._id === cartProduct._id) {
        // @ts-ignore
        if (cartProduct.quantity >= cartProduct.qty) {
          item.disabled = true;
        }
      }
    });

    if (item.qty < 1) {
      removeItem = true;
    }
    if (services.includes(item?.type)) {
      removeItem = false;
    }

    if (item.status !== 'ACTIVE') {
      removeItem = true;
    }
    if (!removeItem) {
      return item;
    }
  });
};

export const filterRelatedProducts = (
  products: any,
  item: any,
  cartProducts: any
) => {
  return products.filter((product) => {
    cartProducts.map((cartProduct) => {
      if (product._id === cartProduct._id) {
        // @ts-ignore
        if (cartProduct.quantity >= cartProduct.qty) {
          product.disabled = true;
        }
      }
    });
    if (
      product._id !== item._id &&
      product.category.toLowerCase() === item.category.toLowerCase()
    ) {
      return product;
    }
  });
};

export const ffilterForQuantity = (products: any) => {
  return products.filter((item) => {
    let removeItem = false;
    if (item.qty > 0) {
      removeItem = true;
    }

    if (services.includes(item?.type)) {
      removeItem = true;
    }

    return removeItem;
  });
};

export const limitCharacters = (str: string) => `${str.substr(0, 300)}...`;

export const getTotalItems = (products: IProductCart[]) => {
  return products.reduce((acc, curr) => {
    return acc + Number(curr.quantity);
  }, 0);
};

export const getSubTotal = (products: IProductCart[]) => {
  return products.reduce((acc, curr) => {
    return acc + Number(curr.quantity) * curr.price;
  }, 0);
};

export const getAllTaxes = (products: any) => {
  let taxes = {};

  products.forEach((product) => {
    let rates = product.tax?.rates || {};
    for (let tax in rates) {
      if (tax in taxes) {
        taxes[tax] += rates[tax] * product.price * product.quantity;
      } else {
        taxes[tax] = rates[tax] * product.price * product.quantity;
      }
    }
  });

  return taxes;
};

export const getTaxTotal = (products: any) => {
  let taxes = getAllTaxes(products);
  let total = 0;
  for (const tax in taxes) {
    total += taxes[tax];
  }
  return total;
};

export const getBnslConvenienceFee = (total, settings) => {
  if (total <= settings?.transaction_min) {
    return settings?.transaction_min_fee;
  }
  return total * settings?.bnsl_convenience_fee;
};
export const formatPrice = (p) => {
  return Number(p).toFixed(2);
};

export const getToken = (): string => {
  try {
    const token = getStoredLocal(APP_NAME_TOKEN) || '';
    return JSON.parse(token);
  } catch (e) {
    return '';
  }
};

export const saveCartItems = (data: any): any => {
  saveLocal(APP_NAME_CART, JSON.stringify(data));
};

export const getCartItems = (stayOrders: any): any => {
  let orders = '';
  try {
    orders = getStoredLocal(APP_NAME_CART);
    if (orders) {
      // @ts-ignore
      orders = JSON.parse(orders);
    }
  } catch (e) {
    throw new Error('Cant get the cart items');
  }

  if (orders && orders.length > 0) {
    return orders;
  }

  return null;
};

export const isEqual = (str1, str2) => {
  return str1.toLowerCase() === str2.toLowerCase();
};
