// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { select, all, put, takeEvery } from 'redux-saga/effects';
import { saveCartItems } from 'utils';
import { APP_NAME_CART } from 'const/index';
import { getStoredLocal } from 'utils/localstorage';
import { ADD_TO_CART, REMOVE_FROM_CART, UPDATE_CART } from '../actionTypes';
import { getProducts } from '../../products/selectors';
import { getCartItems } from '../selectors';
import { IProductCart, IAddToCart, IRemoveFromCart } from '../types';
import { addToCartSuccess, removeFromCartSuccess } from '../actions';

export const saveToDBLocal = (locationTag: any, newCart: any) => {
  const store = getStoredLocal(APP_NAME_CART);
  const localStorage = store ? JSON.parse(store) : null;
  let DATA = [];

  if (localStorage) {
    const isExisting = localStorage.find(
      (item) => item.locationTag === locationTag
    );

    if (isExisting) {
      // has existing data
      DATA = localStorage.map((item: any) => {
        return item.locationTag === locationTag
          ? {
              locationTag,
              cart: newCart,
            }
          : item;
      });
    } else {
      // @ts-ignore
      DATA = [...localStorage, { locationTag, cart: newCart }];
    }

    saveCartItems(DATA);
  } else {
    // empty data

    DATA = [
      {
        // @ts-ignore
        locationTag,
        // @ts-ignore
        cart: newCart,
      },
    ];
    saveCartItems(DATA);
  }
};

export const removeDBLocal = (locationTag: string, newItems: any) => {
  const store = getStoredLocal(APP_NAME_CART);
  const localStorage = store ? JSON.parse(store) : null;

  if (localStorage) {
    const DATA = localStorage.map((item: any) => {
      return item.locationTag === locationTag
        ? { locationTag, cart: newItems }
        : item;
    });

    saveCartItems(DATA);
  }
};

function* addToCartFlow(action: IAddToCart) {
  const products = yield select(getProducts);
  const addedItems = yield select(getCartItems);

  const { payload } = action;
  const { locationTag } = payload;

  // find the the product inside db products
  let addedItem = products.find((item: any) => item._id === payload.item_id);

  // check item exist in cart
  const existed_item = addedItems.find(
    (item: IProductCart) => payload.item_id === item._id
  );

  // distinguish if its new or not
  const newItems = existed_item
    ? addedItems.map((item: IProductCart) =>
        item._id === payload.item_id
          ? {
              ...item,
              quantity: item.quantity + payload.quantity!,
            }
          : item
      )
    : [...addedItems, { ...addedItem, quantity: payload.quantity! }];

  // saving to local storage
  saveToDBLocal(locationTag, newItems);

  yield put(
    addToCartSuccess({
      addedItems: newItems,
    })
  );
}

function* updateCartFlow() {
  const inventory = yield select(getProducts);
  const addedItems = yield select(getCartItems);

  const locationTag = inventory[0]?.location_tag;

  const updatedItems = addedItems.reduce(function (filtered, item) {
    let product = inventory.find((product) => product._id === item._id);
    if (!!product) {
      if (product.qty < item.quantity) {
        item.quantity = product.qty;
      }
      const newItem = { ...product, quantity: item.quantity };
      filtered.push(newItem);
    }
    return filtered;
  }, []);

  // saving to local storage
  saveToDBLocal(locationTag, updatedItems);

  yield put(
    addToCartSuccess({
      addedItems: updatedItems,
    })
  );
}

function* removeCartFlow(action: IRemoveFromCart) {
  const addedItems = yield select(getCartItems);

  // find the product and filter
  const newItems = addedItems.filter(
    (item: IProductCart) => item._id !== action.payload.item_id
  );

  const { locationTag } = action.payload;

  // remove to local storage
  removeDBLocal(locationTag, newItems);

  yield put(
    removeFromCartSuccess({
      addedItems: newItems,
    })
  );
}

function* cartSaga() {
  yield all([
    takeEvery(ADD_TO_CART, addToCartFlow),
    takeEvery(UPDATE_CART, updateCartFlow),
    takeEvery(REMOVE_FROM_CART, removeCartFlow),
  ]);
}

export default cartSaga;
