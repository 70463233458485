import { useState, useEffect } from 'react';
import useQueryStay from 'hooks/useQueryStay';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Footer from 'components/Footer';
import Banner from 'components/Banner';
import Categories from 'components/Categories';
import Tickets from 'components/Tickets/index';
import Viator from 'components/viator/index';
import Header from './Header';
import './styles.scss';
import { useDispatch } from 'react-redux';
import { getSettings } from 'store/stays/selectors';
import ExperienceSearch from 'components/ExperienceSearch';
import {
  getExperiences,
  getExperiencesLoading,
} from 'store/experiences/selectors';
import { fetchExperiencesRequest } from 'store/experiences/actions';
import { getProvider } from 'store/experiences/experience_utils';

const ConciergePage = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const [provider, setProvider] = useState('');
  const settings = useSelector(getSettings);
  const { locationTag = '' } = params;

  // @ts-ignore
  const { response, loading }: any = useQueryStay(locationTag);

  const experiences = useSelector(getExperiences);
  const experiencesLoading = useSelector(getExperiencesLoading);

  const experienceProps = {
    experiences: [...experiences].filter((exp) => !exp.hidden),
    demo: false,
  };

  useEffect(() => {
    if (response) {
      dispatch(fetchExperiencesRequest(response));
      setProvider(getProvider(response));
    }
  }, [response]);

  useEffect(() => {
    if (response?.name) {
      document.title = `${response?.name} Experiences`;
    }
  }, [response]);

  if (loading) {
    return (
      <div
        style={{
          position: 'fixed',
          top: '50%',
          left: '50%',
        }}
        className="spinner-border"
        role="status"
      />
    );
  }

  return (
    <div className="concierge-page">
      <Header />

      <div className="container-xl" style={{ minHeight: '100vh' }}>
        {settings.logo_image && settings.show_location_name && (
          <div translate="no" className="location_name">
            <p> {response?.name?.toUpperCase()}</p>
          </div>
        )}
        {settings.show_banner && (
          <Banner hideExploreBtn={true} locationInfo={response} />
        )}

        {settings.show_categories && experiences?.length ? (
          <Categories experiences={experiences} />
        ) : null}

        {/* {settings.show_experience_search && <ExperienceSearch />} */}

        {experiencesLoading ? (
          <div className="backdrop">
            <div className="loader" />
          </div>
        ) : (
          <div>
            {provider === 'tiqets' ? (
              <Tickets {...experienceProps} demo={false} />
            ) : (
              <Viator {...experienceProps} demo={false} />
            )}
          </div>
        )}
      </div>

      <Footer />
    </div>
  );
};

export default ConciergePage;
