/*eslint-disable */

import {
  FETCH_STAYS_REQUEST,
  FETCH_STAYS_SUCCESS,
  FETCH_STAYS_FAILURE,
} from '../actionTypes';

const defaultSettings = {
  show_location_name: false,
  show_navbar: true,
  show_cart: true,
  show_banner: true,
  banner_title: 'Tours • Attractions • Museums\nShows & more nearby.',
  show_banner_location: true,
  show_banner_button: true,
  banner_button_label: 'EXPLORE NOW',
  banner_button_url: '',
  banner_image: '',
  logo_image: '',
  nav_background_color: '#86a9cb',
  nav_text_color: '#ffffff',
  button_color: '#f81ca4',
  button_text_color: '#ffffff',
  banner_button_color: '#f81ca4',
  banner_button_text_color: '#ffffff',
  show_search_bar: false,
  show_banner_experience: true,
  show_room_number: false,
  show_last_name: false,
  show_categories: false,
  show_experience_search: false,
};

const initialState = {
  loading: true,
  data: {},
  settings: defaultSettings,
  error: null,
};

const getSettings = (location) => {
  let settings =
    location?.settings?.properties || location?.host_settings?.properties;

  if (settings) {
    return {
      ...defaultSettings,
      ...settings,
      custom: true,
    };
  }
  return defaultSettings;
};

const StaysReducer = (state = initialState, action: any): any => {
  switch (action.type) {
    case FETCH_STAYS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_STAYS_SUCCESS:
      let location = action.payload.stays;

      return {
        ...state,
        loading: false,
        data: location,
        settings: getSettings(location),
        error: null,
      };
    case FETCH_STAYS_FAILURE:
      return {
        ...state,
        loading: false,
        data: [],
        error: action.payload.error,
      };
    default:
      return {
        ...state,
      };
  }
};

export default StaysReducer;
