// @ts-nocheck
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { fetchExperiencesSuccess, fetchExperiencesFailure } from '../actions';
import {
  FETCH_EXPERIENCES_REQUEST,
  SET_EXPERIENCES_SEARCH,
  SET_EXPERIENCES_CATEGORY,
} from '../actionTypes';
import { getExperiencesApi, getCustomExperiences } from '../api';
import {
  getExperiences,
  getExperiencesCategory,
  getExperiencesSearch,
} from '../selectors';
import {
  getProvider,
  filterTickets,
  filterViator,
  sortByKeyWord,
  sortCustom,
} from '../experience_utils';

function* fetchExperiencesSaga({ payload }: any) {
  const location = payload;
  if (location?.tag) {
    const hiddenIds = location.hidden_ids;
    const custom = yield call(
      getCustomExperiences,
      location?.tag,
      location?.demo,
      location?.campaignTag
    );
    const customExperiences = sortCustom(custom?.data?.experiences);
    if (customExperiences?.length) {
      yield put(
        fetchExperiencesSuccess({ data: customExperiences, slowLoading: true })
      );
    }

    try {
      const provider = getProvider(location);
      const response = yield call(getExperiencesApi, location);

      //VIATOR
      if (provider === 'viator') {
        let experiences = [];
        response.forEach((item) => {
          experiences.push(...item?.data?.viator?.products);
        });
        experiences = filterViator({
          data: experiences.reverse(),
          location_tag: location?.tag,
          hiddenIds,
        });
        yield put(
          fetchExperiencesSuccess({
            data: [...customExperiences, ...experiences],
            slowLoading: false,
          })
        );
      }
      //TIQETS
      if (provider === 'tiqets') {
        const tickets = filterTickets(
          response?.data?.tickets,
          location,
          hiddenIds
        );
        yield put(
          fetchExperiencesSuccess({
            data: [...customExperiences, ...tickets],
            slowLoading: false,
          })
        );
      }
      //NEW VIATOR
      if (provider === 'newviator') {
        const { first100, data } = response;
        let experiences = [...first100];

        data.forEach((item) => {
          experiences.push(...item?.data?.viator?.products);
        });

        if (experiences.length) {
          experiences = sortByKeyWord(location, experiences);
        }

        experiences = filterViator({
          data: experiences,
          destination: location?.providers?.viator,
          location_tag: location?.tag,
          hiddenIds,
        });

        yield put(
          fetchExperiencesSuccess({
            data: [...customExperiences, ...experiences],
            slowLoading: false,
          })
        );
      }
    } catch (e: any) {
      yield put(
        fetchExperiencesFailure({
          error: e?.message,
          data: customExperiences || [],
        })
      );
    }
  }
}

function* setExperienceFilterSaga() {
  const category = yield select(getExperiencesCategory);
  const searchTerm = yield select(getExperiencesSearch);

  let experiences = yield select(getExperiences);
  let filtered = [];

  filtered = experiences.map((exp) => {
    if (category === 'Experiences' && (!!exp.id || !!exp.productCode)) {
      return { ...exp, hidden: false };
    } else if (
      (!!exp._id && (exp.category || []).includes(category)) ||
      category === 'All'
    ) {
      return { ...exp, hidden: false };
    } else {
      return { ...exp, hidden: true };
    }
  });

  if (searchTerm) {
    filtered = filtered.map((exp) => {
      if (!exp.hidden) {
        const show =
          exp.title.trim().toLowerCase().includes(searchTerm) ||
          (exp.category || [])
            .join()
            .trim()
            .toLowerCase()
            .includes(searchTerm) ||
          (exp.description || '').trim().toLowerCase().includes(searchTerm) ||
          (exp.tagline || '').trim().toLowerCase().includes(searchTerm);
        return { ...exp, hidden: !show };
      } else {
        return exp;
      }
    });
  }

  yield put(fetchExperiencesSuccess({ data: filtered, filtered: true }));
}

function* experiencesSaga() {
  yield all([
    takeLatest(FETCH_EXPERIENCES_REQUEST, fetchExperiencesSaga),
    takeLatest(
      [SET_EXPERIENCES_SEARCH, SET_EXPERIENCES_CATEGORY],
      setExperienceFilterSaga
    ),
  ]);
}

export default experiencesSaga;
