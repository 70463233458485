import React from 'react';
import HostForm from 'components/HostForm';

const HomePaigeContainer = () => {
  return (
    <div className="homepage">
      <HostForm />
    </div>
  );
};

export default HomePaigeContainer;
