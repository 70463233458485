import {
  AUTHENTICATE_USER,
  AUTHENTICATE_USER_SUCCESS,
  AUTHENTICATE_USER_FAILURE,
} from '../actionTypes';

import {
  IAuthenticateUserPayload,
  IAuthenticateUser,
  IAuthenticateUserSuccessPayload,
  IAuthenticateUserSuccess,
  IAuthenticateUserFailsPayload,
  IAuthenticateUserFails,
} from '../types';

export const authenticateUser = (
  payload: IAuthenticateUserPayload
): IAuthenticateUser => ({
  type: AUTHENTICATE_USER,
  payload,
});

export const authenticateUserSuccess = (
  payload: IAuthenticateUserSuccessPayload
): IAuthenticateUserSuccess => ({
  type: AUTHENTICATE_USER_SUCCESS,
  payload,
});

export const authenticateUserFailure = (
  payload: IAuthenticateUserFailsPayload
): IAuthenticateUserFails => ({
  type: AUTHENTICATE_USER_FAILURE,
  payload,
});
