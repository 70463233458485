import React from 'react';
import { PaymentContext } from 'Context/PaymentContext';
import PurchaseItem from './PurchaseItem';

const PurchaseList = ({ symbol }:any) => {
  // @ts-ignore
  const { paymentInformation } = React.useContext(PaymentContext);
  const { products = [] } = paymentInformation;

  const hasTax = !!products.find(
    (product) => Object.keys(product.tax?.rates || [])?.length
  );

  return (
    <ul className="purchase-item" style={{ paddingTop: 4 }}>
      <div className="cart_list__column-labels clearfix price_tax_order">
        {hasTax && <p className="price_tax_order__tax">TAX</p>}
        <p className="price_tax_order__price">PRICE</p>
      </div>
      {products.map((item: any) => (
        <PurchaseItem
          hasTax={hasTax}
          {...item}
          _id={item._id}
          key={item.name}
          symbol={symbol}
        />
      ))}

      <hr className="my-3 " style={{ borderTopColor: '#999' }} />
    </ul>
  );
};

export default PurchaseList;
