// @ts-nocheck
import { useEffect, useState, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { toast } from 'react-toastify';
import {
  getBnslConvenienceFee,
  formatPrice,
  getSubTotal,
  getTaxTotal,
} from 'utils';
import { useParams } from 'react-router-dom';
import {
  PaymentRequestButtonElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import { getCartItems } from 'store/cart/selectors';
import { PaymentContext } from 'Context/PaymentContext';
import { emptyCart } from 'store/cart/actions';
import axios from 'axiosInstance';
import { getStays } from 'store/stays/selectors';
import { getGlobalSettings } from 'store/globalSettings/selectors';

const ApplePay = () => {
  const dispatch = useDispatch();
  const products = useSelector(getCartItems);
  const globalSettings = useSelector(getGlobalSettings);

  let { locationTag } = useParams();
  const location = useSelector(getStays);

  const stripe = useStripe();
  const [loading, setLoading] = useState(false);
  const elements = useElements();
  const [paymentRequest, setPaymentRequest] = useState(null);
  const { setPaymentInformation, setSuccessPayment } =
    useContext(PaymentContext);

  useEffect(() => {
    const EffectFunc = async () => {
      let location_tag = null;
      let currency = location?.currency.toLowerCase() || 'cad';
      let country = location?.address?.country.toUpperCase().trim() || 'US';
      const items = products.map((item: any) => {
        location_tag = item?.location_tag;
        return { id: item._id, qty: item.quantity };
      });

      let totalOld = formatPrice(
        getSubTotal(products) +
          getBnslConvenienceFee(getSubTotal(products), globalSettings) +
          getTaxTotal(products)
      );
      let total = +Math.ceil((totalOld * 100).toFixed(2));
      // @ts-ignore
      const pr = stripe.paymentRequest({
        country: country,
        currency: currency,
        total: {
          label: 'total',
          amount: total,
        },
        requestPayerName: true,
        requestPayerEmail: true,
      });

      // Check the availability of the Payment Request API.
      pr.canMakePayment().then((result) => {
        if (result) {
          // @ts-ignore
          setPaymentRequest(pr);
        }
      });
      pr.on('paymentmethod', async (e) => {
        try {
          const data = await axios.post(`/locations/${location_tag}/payment`, {
            total: totalOld,
          });
          const { client_secret } = data?.data;
          const { error: stripeError, paymentIntent } =
            await stripe.confirmCardPayment(client_secret, {
              payment_method: e.paymentMethod.id,
            });
          if (stripeError) {
            e.complete('fail');
            // Show error to your customer (e.g., insufficient funds)
            const error = new Error(
              stripeError?.message || 'Something went Wrong'
            );
            throw error;
          }
          e.complete('success');
          const resp = await axios.post(
            `/locations/${location_tag}/transaction`,
            {
              id: paymentIntent.id,
              items,
            }
          );
          setLoading(false);
          if (resp.data) {
            handleResponse(resp.data.transaction);
          }
        } catch (err) {
          setLoading(false);
          toast.error('Sorry Unable to send payment this time', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          return false;
        }
      });
    };
    if (stripe && elements && location) {
      EffectFunc();
    }
  }, [stripe, elements, location]);

  const handleResponse = (rs: any) => {
    if (rs) {
      const successpayload = getSuccessPayload(rs);
      setPaymentInformation(successpayload);
      setSuccessPayment(true);
      dispatch(emptyCart({ locationTag }));
    }
  };
  const getSuccessPayload = (resp: any) => {
    const {
      number,
      created_at,
      total_net,
      payment_method,
      payment_card_name,
      total,
      bnsl_convenience_fee,
      total_sub,
      tax,
    } = resp;
    const newProducts = products.map((item) => {
      return {
        image: item?.images[0],
        price: item.price,
        name: item.title,
        quantity: item.quantity ? item.quantity : item.qty,
      };
    });
    return {
      orderNumber: number,
      date: moment(created_at).format('MMMM DD YYYY'),
      name: payment_card_name,
      method: payment_method,
      products: newProducts,
      total,
      sub: total_net,
      bnsl_convenience_fee,
      total_sub: total_sub,
      tax,
    };
  };

  return (
    <>
      {paymentRequest && (
        <PaymentRequestButtonElement options={{ paymentRequest }} />
      )}
    </>
  );
};

export default ApplePay;
