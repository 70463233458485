import ApplePay from './applePay';
import FormPayment from './FormPayment';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js/pure';
import { STRIPE_CA_PK, STRIPE_US_PK } from 'const/index';
import './styles.scss';
import { useEffect, useState } from 'react';
import { getStays } from 'store/stays/selectors';
import { useSelector } from 'react-redux';

const CheckoutForm = () => {
  const location = useSelector(getStays);

  const [stripPromiss, setStripPromiss] = useState(null);
  useEffect(() => {
    const useffectFunc = async () => {
      // @ts-ignore-start
      if (location?.currency) {
        let stripe_key = '';
        // @ts-ignore-start
        if (location?.currency === 'USD') {
          stripe_key = STRIPE_US_PK;
        }
        // @ts-ignore-start
        if (location?.currency === 'CAD') {
          stripe_key = STRIPE_CA_PK;
        }
        // @ts-ignore-start
        
        if (
          ['62fe5257512a1af34ec911b6'].includes(
            location?.host_id
          )
        ) {
          stripe_key = STRIPE_CA_PK;
        }
        // @ts-ignore-start
        const stripeRes = await loadStripe(stripe_key, {
          stripeAccount: location?.meta?.stripe_acct_id,
        });
        // @ts-ignore-start
        setStripPromiss(stripeRes);
      }
      //test
    };
    useffectFunc();
    // @ts-ignore-start
  }, [location?.currency]);
  if (!stripPromiss) {
    return <div>Loadiing ....</div>;
  }
  return (
    <Elements stripe={stripPromiss}>
      <div className="checkout_form">
        <h3 className="checkout_form__info">GUEST CHECKOUT</h3>
        <p className="checkout_form__desc">
          By continuing you agree to our{' '}
          <a
            href="https://bnbuyit.com/terms-of-service/"
            target="_blank"
            rel="noreferrer"
          >
            {' '}
            Terms of Service{' '}
          </a>
          ,
          <a
            href="https://bnbuyit.com/release-and-waiver/"
            target="_blank"
            rel="noreferrer"
          >
            {' '}
            Release & Waiver
          </a>{' '}
          and{' '}
          <a
            href="https://bnbuyit.com/privacy-policy/"
            target="_blank"
            rel="noreferrer"
          >
            Privacy Policy
          </a>
          .
        </p>

        <div style={{ paddingBottom: 10, paddingTop: 10 }}>
          <ApplePay />
        </div>
        <br />
        <h3 className="checkout_form__header">PAYMENT BY CREDIT CARD</h3>
        {/* APPLE APAY BUTTON */}

        <FormPayment />
      </div>
    </Elements>
  );
};

export default CheckoutForm;
