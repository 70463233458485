// @ts-nocheck
import { CLIENT_KEY, CLIENT_ID, GUEST_TOKEN } from 'const/index';
import axios from 'axiosInstance';
import axiosOriginal from 'axios';
import {
  getNearestLngAndLat,
  getAddress,
  getProvider,
} from '../experience_utils';
import Geocode from 'react-geocode';

Geocode.setApiKey('AIzaSyDg6TAhVQJ5OgnBFYyUtG6I_1DH_x2VzGU');
Geocode.setLanguage('en');

export const getExperiencesApi = async (location: any) => {
  const provider = getProvider(location);

  if (provider === 'tiqets' || provider === 'viator') {
    // const address = getAddress(location?.address);
    // const response = await Geocode.fromAddress(address);
    // const { lat, lng } = response.results[0].geometry.location;
    let lat = location?.latitude;
    let lng = location?.longitude;
    if (!lat && !lng) {
      const address = getAddress(location?.address);
      const response = await Geocode.fromAddress(address);
      const cordinates = response.results[0].geometry.location;
      lat = cordinates.lat;
      lng = cordinates.lng;
    }
    //VIATOR
    if (provider === 'viator') {
      let multiRequest = [];
      let result = getNearestLngAndLat(lat, lng);
      if (location.preview) {
        result = result.slice(-2);
      }
      result?.map((item) => {
        multiRequest.push(axios.get(item.url));
      });

      return axiosOriginal.all(multiRequest, {
        headers: {
          'Client-Key': CLIENT_KEY,
          'Client-ID': CLIENT_ID,
          Authorization: `Bearer ${GUEST_TOKEN}`,
        },
      });
    }
    //TIQETS
    if (provider === 'tiqets') {
      return axios.get(
        `/aff/tiquets?lang=en&page_size=${
          location.preview ? 20 : 100
        }&lat=${lat}&lng=${lng}&max_distance=100&currency=${location.currency}`
      );
    }
  }
  //NEW VIATOR
  if (provider === 'newviator') {
    const providerData = location?.providers?.viator;
    const res = await axios.get(
      `/aff/viator?destination=${providerData.destination_id}&currency=${
        providerData.default_currency_code
      }&start=1&pagination=${location.preview ? 20 : 50}`
    );
    const totalCount = res?.data?.viator?.totalCount;
    const first100 = res?.data?.viator?.products;

    if (totalCount) {
      const length = Math.ceil(totalCount / 100);
      let indexArray = Array.from({ length: length - 1 }, (x, i) => i + 1);
      if (location.preview) {
        indexArray = [];
      }
      const multiRequest = [];
      indexArray.map((item) => {
        let start = item * 100;
        multiRequest.push(
          axios.get(
            `/aff/viator?destination=${providerData.destination_id}&currency=${providerData.default_currency_code}&start=${start}&pagination=50`
          )
        );
      });

      const data = await axiosOriginal.all(multiRequest, {
        headers: {
          'Client-Key': CLIENT_KEY,
          'Client-ID': CLIENT_ID,
          Authorization: `Bearer ${GUEST_TOKEN}`,
        },
      });

      return {
        first100,
        data,
      };
    }
  }
};

export const getCustomExperiences = (tag: any, demo: any, campaignTag: any) => {
  return axios.get(
    `/locations/tag/${tag}${!!demo ? `/demo/${campaignTag}` : ''}/experiences`
  );
};
