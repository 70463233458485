import './styles.scss';
import { getSettings } from 'store/stays/selectors';
import { useSelector } from 'react-redux';

interface IProps {
  price: number;
  disabled?: boolean;
  handleAddToCart: () => void;
}

const AddToCart = ({ handleAddToCart, price, disabled }: IProps) => {
  const settings = useSelector(getSettings);

  return (
    <button
      disabled={disabled}
      type="button"
      className={disabled ? 'cartButton disabled' : 'cartButton'}
      onClick={handleAddToCart}
      style={{
        color: settings.button_text_color,
        background: settings.button_color,
      }}
    >
      Add to Cart
    </button>
  );
};

export default AddToCart;
