import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { getProductsError } from 'store/products/selectors';
import { AiFillRightCircle } from 'react-icons/ai';
import { HiLocationMarker } from 'react-icons/hi';
import { getStays, getSettings } from 'store/stays/selectors';
import bannerImage from '../../assets/images/concierge_bg.jpg';
import ExperienceCarousel from 'components/ExperienceCarousel';
import { gaViewInventory } from 'utils/google_analytics';
import styled from 'styled-components';
import {
  getExperiences,
  getExperiencesLoading,
} from 'store/experiences/selectors';
import './styles.scss';

const BannerContainer = styled.div`
  background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
    url(${(props) => props.bannerImage});
  height: ${(props) => (props.hide ? '300px' : '470px')};
  .banner-text {
    max-width: 680px;
    margin-top: ${(props) => !props.hide && '-20px'};
  }
  @media screen and (max-width: 600px) {
    height: ${(props) => props.hide && '180px'};
    .banner-text {
      margin-top: ${(props) => !props.hide && '-35px'};
    }
  }
`;

const Banner = ({ hideExploreBtn = false, locationInfo }: any) => {
  const params = useParams();
  const prodError = useSelector(getProductsError);
  const stay = useSelector(getStays);
  const settings = useSelector(getSettings);
  const experiences = useSelector(getExperiences);
  const experiencesLoading = useSelector(getExperiencesLoading);
  const location = locationInfo ? locationInfo : stay;

  const { locationTag } = params;

  let bannerLink = settings.banner_button_url;

  const bannerStyle = {
    color: settings.banner_button_text_color,
    background: settings.banner_button_color,
  };

  const renderButton = (error: any) => {
    if (hideExploreBtn) {
      return '';
    }

    if (error) {
      return (
        <button className="btn" type="button">
          EXPLORE NOW
        </button>
      );
    }

    return settings.show_banner_button ? (
      <div
        onClick={() =>
          gaViewInventory(
            settings.banner_button_label,
            locationTag,
            'experience',
            'explore_button'
          )
        }
      >
        {bannerLink ? (
          <a
            className="btn"
            target="_blank"
            href={bannerLink}
            style={bannerStyle}
            rel="noreferrer"
          >
            {settings.banner_button_label}
            <AiFillRightCircle className="arrow-right-icon" />
          </a>
        ) : (
          <Link
            className="btn"
            target="_blank"
            to={`/${locationTag}/concierge`}
            style={bannerStyle}
          >
            {settings.banner_button_label}
            <AiFillRightCircle className="arrow-right-icon" />
          </Link>
        )}
      </div>
    ) : null;
  };
  const banner_title_array = settings.banner_title?.split('\n');

  return (
    <BannerContainer
      bannerImage={settings.banner_image || bannerImage}
      className="banner-container"
      hide={
        hideExploreBtn ||
        !settings.show_banner_experience ||
        (!experiencesLoading && !experiences?.length)
      }
    >
      <div className="banner-info">
        <div className="banner-text">
          {banner_title_array.map((text, i) => (
            <span key={i}>{text}</span>
          ))}
          {settings.show_banner_location && location?.address && (
            <p className="banner-place">
              <HiLocationMarker className="location-icon" />
              {location?.address?.city},{' '}
              {location?.address?.state || location?.address?.country}
            </p>
          )}
        </div>

        {renderButton(prodError)}
      </div>
      {!hideExploreBtn && settings.show_banner_experience && (
        <ExperienceCarousel buttonLabel={settings.banner_button_label} />
      )}
    </BannerContainer>
  );
};

export default Banner;
