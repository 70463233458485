import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import { getProducts } from 'store/products/selectors';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { getSettings, getStays } from 'store/stays/selectors';

import './styles.scss';

const faAngleLeftIcon = faAngleLeft as IconProp;
const Header = () => {
  const settings = useSelector(getSettings);
  const [className, setClassName] = useState('');
  const [scrollPos, setScrollPos] = useState(0);
  const products = useSelector(getProducts);
  const params = useParams();
  const location = useSelector(getStays);

  useEffect(() => {
    if (scrollPos >= 40) {
      setClassName('sticky-top');
    } else {
      setClassName('');
    }
  }, [scrollPos]);

  useEffect(() => {
    window.addEventListener('scroll', scroll);
    return () => {
      window.removeEventListener('scroll', scroll);
    };
  });

  const scroll = () => {
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;

    setScrollPos(winScroll);
  };

  const path = window.location.href;

  return (
    <div>
      <div className="changeLanguage"> CHANGE LANGUAGE </div>

      <div className="header_section header_section_concierge">
        <div className="top-header-wrapper">
          <Container>
            <div className={`topHeaderSection ${className}`}>
              <Link
                to={`/${params?.locationTag}`}
                className="topHeaderSection__host"
              >
                {/* @ts-ignore */}
                <div
                  className={
                    products.length < 1 ? 'topHeaderSection__hidden' : ''
                  }
                >
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faAngleLeftIcon}
                      className="topHeaderSection__back"
                      style={{
                        fontSize: '28px',
                        color: '#000',
                      }}
                    />
                    {/* <img src={Logo} alt="logo" className="topHeaderSection__logo" />{' '} */}
                    <span className="back-to-home"> </span>
                  </div>
                </div>
                <span className="topHeaderSection__hostId">
                  {params.location_tag}
                </span>
              </Link>
              {/* @ts-ignore */}

              {settings.logo_image ? (
                <div className="topHeaderSection__title">
                  <img
                    alt="logo"
                    src={settings.logo_image}
                    className="logo_image"
                  />
                </div>
              ) : (
                <div translate="no" className="topHeaderSection__title">
                  <h3 translate="no" className="topHeaderSection__title">
                    {location?.name?.toUpperCase() || 'Marketplace'}
                  </h3>
                </div>
              )}
            </div>
          </Container>
        </div>
      </div>
    </div>
  );
};

export default Header;
