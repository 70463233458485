import './styles.scss';
import { useSelector, useDispatch } from 'react-redux';
import Slider from 'react-slick';
import {
  getExperiences,
  getExperiencesLoading,
} from 'store/experiences/selectors';
import { useEffect, useState } from 'react';
import { fetchExperiencesRequest } from 'store/experiences/actions';
import { getStays } from 'store/stays/selectors';
import { getProvider } from 'store/experiences/experience_utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { gaViewInventory } from 'utils/google_analytics';

const ExperienceCarousel = ({ buttonLabel }) => {
  const experiences = useSelector(getExperiences);
  const location = useSelector(getStays);
  const dispatch = useDispatch();
  const [random, setRandom] = useState([]);
  const provider = getProvider(location);
  const loading = useSelector(getExperiencesLoading);

  useEffect(() => {
    if (!experiences || !experiences?.length) {
      dispatch(fetchExperiencesRequest({ ...location, preview: true }));
    }
  }, [location]);

  useEffect(() => {
    if (experiences.length) {
      const shuffled = experiences.sort(() => 0.5 - Math.random());
      setRandom(shuffled.slice(0, 4));
    }
  }, [experiences]);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
  };

  const openConcierge = () => {
    window.open(`/${location?.tag}/concierge`, '_blank');
    gaViewInventory(
      buttonLabel,
      location?.tag,
      'experience',
      'experience_preview'
    );
  };

  const CustomExperience = (item) => {
    return (
      <div key={item._id} className="experience_slide" onClick={openConcierge}>
        <img src={item.images[0]} />
        <div className="experience_info">
          <div className="experience_title">{item.title}</div>
          {item?.rating ? (
            <div className="experience_rating">
              <FontAwesomeIcon
                icon={faStar}
                style={{
                  fontSize: '16px',
                  marginRight: '4px',
                  color: '#FFBD24',
                  paddingBottom: 6,
                }}
              />
              {`${item.rating} / 5`}
              {item?.total_rating && ` (${item?.total_rating})`}
            </div>
          ) : null}
        </div>
      </div>
    );
  };
  if (loading) {
    return (
      <div className="experiences_loader">
        <div className="spinner-border" role="status" />
      </div>
    );
  }

  if (experiences.length) {
    return (
      <Slider {...settings} className="experience_carousel_container">
        {provider !== 'tiqets'
          ? random.map((item: any, i) => {
              if (item?._id) {
                return CustomExperience(item);
              }
              return (
                <div
                  key={i}
                  className="experience_slide"
                  onClick={openConcierge}
                >
                  <img
                    src={
                      item.images[0]?.variants
                        ? item.images[0]?.variants[9]?.url
                        : ''
                    }
                  />
                  <div className="experience_info">
                    <div className="experience_title">{item.title}</div>
                    {item?.reviews && (
                      <div className="experience_rating">
                        <FontAwesomeIcon
                          icon={faStar}
                          style={{
                            fontSize: '16px',
                            marginRight: '4px',
                            color: '#FFBD24',
                            paddingBottom: 6,
                          }}
                        />
                        {`${item?.reviews?.combinedAverageRating?.toFixed(
                          1
                        )} / 5 (${item?.reviews?.totalReviews})`}
                      </div>
                    )}
                  </div>
                </div>
              );
            })
          : random.map((item: any, i) => {
              if (item?._id) {
                return CustomExperience(item);
              }
              return (
                <div
                  key={i}
                  className="experience_slide"
                  onClick={openConcierge}
                >
                  <img src={item.images[0] ? item.images[0].large : ''} />
                  <div className="experience_info">
                    <div className="experience_title">{item.title}</div>
                    {item?.ratings?.total > 0 && (
                      <div className="experience_rating">
                        <FontAwesomeIcon
                          icon={faStar}
                          style={{
                            fontSize: '16px',
                            marginRight: '4px',
                            color: '#FFBD24',
                            paddingBottom: 6,
                          }}
                        />
                        {`${item.ratings.average} / 5 (${item.ratings.total})`}
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
      </Slider>
    );
  } else {
    return null;
  }
};

export default ExperienceCarousel;
