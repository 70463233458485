import React from 'react';
import './styles.scss';
import { getSettings } from 'store/stays/selectors';
import { useSelector } from 'react-redux';

interface IProps {
  handleConcierge: () => void;
}

const ConciergeButton = ({ handleConcierge }: IProps) => {
  const settings = useSelector(getSettings);

  return (
    <button
      style={{
        color: settings.button_text_color,
        background: settings.button_color,
      }}
      type="button"
      className="cartButton"
      onClick={handleConcierge}
    >
      Learn More
    </button>
  );
};

export default ConciergeButton;
