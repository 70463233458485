import { combineReducers } from 'redux';
import cartReducer from './cart/reducer';
import productsReducer from './products/reducer';
import currenciesReducer from './currencies/reducer';
import categoriesReducer from './categories/reducer';
import authReducer from './auth/reducer';
import staysReducer from './stays/reducer';
import experiencesReducer from './experiences/reducer';
import globalSettingsReducer from './globalSettings/reducer';

const rootReducer = combineReducers({
  currencies: currenciesReducer,
  products: productsReducer,
  categories: categoriesReducer,
  cart: cartReducer,
  auth: authReducer,
  stays: staysReducer,
  experiences: experiencesReducer,
  globalSettings: globalSettingsReducer,
});

export type AppState = ReturnType<typeof rootReducer>;

export default rootReducer;
