import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import useQueryProducts from 'hooks/useQueryProducts';
import useCart from 'hooks/useCart';
import ProductBaseLayout from 'components/Layout/ProductBaseLayout';
import ProductList from 'components/ProductList';
import AppBox from 'components/AppBox';
import { getStaysLoading } from 'store/stays/selectors';

interface IProps {
  hideFilter: boolean;
}

const StayLocationPageContainer = ({ hideFilter }: IProps) => {
  const dispatch = useDispatch();
  const loading = useSelector(getStaysLoading);
  let { locationTag } = useParams();
  useCart(locationTag as string);
  useQueryProducts(locationTag as string);

  useEffect(() => {
    dispatch({
      type: 'FETCH_STAYS_REQUEST',
      payload: {
        location_tag: locationTag,
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return (
      <div
        style={{
          position: 'fixed',
          top: '50%',
          left: '50%',
        }}
        className="spinner-border"
        role="status"
      />
    );
  }

  return (
    <ProductBaseLayout hideFilter={hideFilter}>
      <div className="productPage">
        <AppBox />
        <ProductList locationTag={locationTag as string} />
      </div>
    </ProductBaseLayout>
  );
};

export default StayLocationPageContainer;
