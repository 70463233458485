export const gaViewInventory = (title, locationTag, type, id) => {
  //@ts-ignore
  return gtag('event', 'view_inventory', {
    product_type: type,
    product_title: title,
    location_tag: locationTag.toLowerCase(),
    item_id: id,
  });
};

export const gaAddToCart = (title, locationTag, id) => {
  //@ts-ignore
  return gtag('event', 'add_to_cart', {
    product_type: 'shop',
    product_title: title,
    location_tag: locationTag.toLowerCase(),
    item_id: id,
  });
};

export const gaServiceRequest = (title, locationTag, id) => {
  //@ts-ignore
  return gtag('event', 'service_request', {
    product_type: 'service',
    product_title: title,
    location_tag: locationTag.toLowerCase(),
    item_id: id,
  });
};

export const gaConciergeClick = (title, locationTag, label, id) => {
  //@ts-ignore
  return gtag('event', 'concierge_button_click', {
    product_type: 'concierge',
    product_title: title,
    button_label: `${label} - ${title}`,
    location_tag: locationTag.toLowerCase(),
    item_id: id,
  });
};

export const gaExperienceClick = (title, locationTag, id) => {
  //@ts-ignore
  return gtag('event', 'experience_click', {
    product_type: 'experience',
    product_title: title,
    location_tag: locationTag.toLowerCase(),
    item_id: id,
  });
};

export const gaExperienceButtonClick = (title, locationTag, label, id) => {
  //@ts-ignore
  return gtag('event', 'experience_button_click', {
    product_type: 'experience',
    product_title: title,
    button_label: `${label} - ${title}`,
    location_tag: locationTag.toLowerCase(),
    item_id: id,
  });
};
