import { useEffect } from 'react';
import CheckoutPageContainer from '../../containers/CheckoutPageContainer';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getStaysLoading } from 'store/stays/selectors';

import './styles.scss';

const CheckoutPage = () => {
  const loading = useSelector(getStaysLoading);
  const dispatch = useDispatch();
  let { locationTag } = useParams();

  useEffect(() => {
    dispatch({
      type: 'FETCH_STAYS_REQUEST',
      payload: {
        location_tag: locationTag,
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (loading) {
    return (
      <div
        style={{
          position: 'fixed',
          top: '50%',
          left: '50%',
        }}
        className="spinner-border"
        role="status"
      />
    );
  }

  return (
    <div>
      {' '}
      <CheckoutPageContainer />
    </div>
  );
};

export default CheckoutPage;
