/*eslint-disable */

import { ICategoriesState, CategoriesActions } from '../types';

import {
  FETCH_CATEGORIES_SUCCESS,
  FETCH_CATEGORIES_FAILURE,
  SET_ACTIVE_CATEGORY,
} from '../actionTypes';

const defaultCategory = {
  _id: 'all',
  name: 'All',
};

const initialState: ICategoriesState = {
  loading: false,
  data: [],
  error: null,
  selected: defaultCategory,
};
/* eslint default-param-last: ["error"] */
const CategoriesReducer = (
  state = initialState,
  action: CategoriesActions
): ICategoriesState => {
  switch (action.type) {
    case FETCH_CATEGORIES_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.categories,
        error: null,
      };
    case FETCH_CATEGORIES_FAILURE:
      return {
        ...state,
        loading: false,
        data: [],
        error: action.payload.error,
      };
    case SET_ACTIVE_CATEGORY:
      return {
        ...state,
        selected: action.payload.category,
      };
    default:
      return {
        ...state,
      };
  }
};

export default CategoriesReducer;
