import { all, call, put, takeLatest } from 'redux-saga/effects';
import { AUTHENTICATE_USER } from '../actionTypes';
import { authenticateUserSuccess, authenticateUserFailure } from '../actions';
import { authenticateUser } from '../api';

function* handleAuthUser(payload) {
  try {
    const response = yield call(authenticateUser, payload);
    const { user } = response.data.data;

    yield put(
      authenticateUserSuccess({
        user: { ...user, token: response.headers.a_t },
      })
    );
  } catch (e: any) {
    yield put(
      authenticateUserFailure({
        error: e.message,
      })
    );
  }
}

function* authenticateUserFlow({ payload, type }: any) {
  switch (type) {
    case AUTHENTICATE_USER:
      yield handleAuthUser(payload);
      break;
  }
}

function* authenticateSaga() {
  yield all([takeLatest(AUTHENTICATE_USER, authenticateUserFlow)]);
}

export default authenticateSaga;
