import DefaultImage from 'assets/images/default-image.png';
import { formatPrice } from 'utils';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const PurchaseItem = ({
  image,
  name,
  price,
  quantity,
  tax,
  hasTax,
  symbol,
}: any) => {
  let rates = Object.keys(tax?.rates || {});
  return (
    <li
      key={name}
      style={{ backgroundColor: 'white', margin: 14, position: 'relative' }}
    >
      <img
        src={image || DefaultImage}
        alt="product"
        style={{ height: 120, width: 120 }}
      />
      <span className="purchase-item__title">
        <p>{name}</p>
        <p style={{ fontSize: '12px', fontWeight: 'lighter' }}>
          QTY : {quantity}
        </p>
      </span>

      <Container
        className="price_tax_item_order"
        style={{
          textAlign: hasTax ? 'center' : 'right',
        }}
      >
        <Row className="price_tax_item_order__row">
          <Col xs={hasTax ? 6 : 0}>
            <span className="cart_list__taxLabel">
              {rates.length ? (
                <div style={{ fontSize: '13px' }}>
                  (
                  {rates.map((tax, i) => (
                    <span key={i}>{(i ? ', ' : '') + tax.substring(0, 1)}</span>
                  ))}
                  )
                </div>
              ) : null}
            </span>
          </Col>
          <Col xs={hasTax ? 6 : 12}>
            <span
              className="purchase-item__price"
              style={{ whiteSpace: 'nowrap' }}
            >
              {symbol}
              {formatPrice(price)}
            </span>
          </Col>
        </Row>
      </Container>
    </li>
  );
};

export default PurchaseItem;
