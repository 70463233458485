import ConciergeView from './concierge';
import ShopView from './shop';
import ExpressCheckoutView from './expressCheckout';
import HouseKeepingView from './houseKeeping';
import './styles.scss';

const ProductSingle = ({
  locationTag,
  _id,
  title,
  price,
  description,
  images,
  category,
  type,
  meta,
  disabled,
}: any) => {
  const shopInventory = type.toLocaleLowerCase() === 'shop';
  const conciergeInventory = type.toLocaleLowerCase() === 'concierge';

  return (
    <>
      {shopInventory && (
        <ShopView
          locationTag={locationTag}
          _id={_id}
          title={title}
          price={price}
          description={description}
          images={images}
          category={category}
          type={type}
          meta={meta}
          disabled={disabled}
        />
      )}
      {conciergeInventory && (
        <ConciergeView
          locationTag={locationTag}
          _id={_id}
          title={title}
          price={price}
          description={description}
          images={images}
          category={category}
          type={type}
          meta={meta}
        />
      )}
      {category === 'Express Checkout' && (
        <ExpressCheckoutView
          locationTag={locationTag}
          _id={_id}
          title={title}
          price={price}
          description={description}
          images={images}
          category={category}
          type={type}
          meta={meta}
        />
      )}
      {['housekeeping', 'maintenance'].includes(category.toLowerCase()) && (
        <HouseKeepingView
          locationTag={locationTag}
          _id={_id}
          title={title}
          price={price}
          description={description}
          images={images}
          category={category}
          type={type}
          meta={meta}
        />
      )}
    </>
  );
};

export default ProductSingle;
