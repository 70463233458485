import { formatPrice, getAllTaxes } from 'utils';
import './styles.scss';

const Taxes = ({ products,symbol }: any) => {
  let taxes = getAllTaxes(products);

  return (
    <>
      {Object.keys(taxes).map((tax, i) => (
        <div key={i} className="formPayment__amount-to-pay">
          <label className="tax_label">
            {`(${tax.substring(0, 1)})${tax.substring(1)}`}
          </label>
          <div>{symbol}{formatPrice(taxes[tax])}</div>
        </div>
      ))}

      {Object.keys(taxes).length ? (
        <div className="formPayment__amount-to-pay">
          <div className="line" />
        </div>
      ) : null}
    </>
  );
};

export default Taxes;
