// @ts-nocheck

import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CustomerInfo from './CustomerInfo';
import useLocationCurrency from 'hooks/useLocationCurrency';
import PurchaseList from './PurchaseList';
import SubTotal from './SubTotal';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { getSettings } from 'store/stays/selectors';
import { useSelector } from 'react-redux';
import './styles.scss';

const OrderSummary = ({ locationTag }: any) => {
  const settings = useSelector(getSettings);

  const { currencyInfo } = useLocationCurrency();
  const symbol =
    currencyInfo?.symbol?.length > 1
      ? `${currencyInfo?.symbol} `
      : currencyInfo?.symbol;
  return (
    <div className="row order-summary">
      <div className="header-container">
        <br />
        <br />
        <a href={`/${locationTag}`} className="back-to-home">
          <FontAwesomeIcon icon={faArrowLeft} />
          Continue Shopping{' '}
        </a>
      </div>
      <div className="col-12 col-md-5">
        <div
          className="order-summary__check"
          style={{ background: settings.button_color }}
        >
          <FontAwesomeIcon
            className="order-summary__check_icon"
            icon={faCheck}
            style={{
              color: settings.button_text_color,
            }}
          />
        </div>

        <div className="order-summary__response-message">
          <h3>Thank you for your purchase!</h3>
          <p>
            Your order was processed successfully. You may now use the item
            during your stay and/or take it home when you leave.
          </p>
        </div>
        <CustomerInfo />
      </div>
      <div className="col-12 col-md-7" style={{ paddingLeft: 3 }}>
        <h3 className="summary-header">ORDER SUMMARY</h3>
        <div
          style={{
            backgroundColor: 'white',
            borderRadius: '12px',
            position: 'relative',
          }}
        >
          <PurchaseList symbol={symbol} />
          <SubTotal symbol={symbol} />
        </div>
      </div>
    </div>
  );
};

export default OrderSummary;
