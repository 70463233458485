import {
  FETCH_EXPERIENCES_REQUEST,
  FETCH_EXPERIENCES_SUCCESS,
  FETCH_EXPERIENCES_FAILURE,
  SET_EXPERIENCES_CATEGORY,
  SET_EXPERIENCES_SEARCH,
} from '../actionTypes';

export const fetchExperiencesRequest = (payload: any): any => {
  return {
    type: FETCH_EXPERIENCES_REQUEST,
    payload,
  };
};

export const fetchExperiencesSuccess = (payload: any): any => {
  return {
    type: FETCH_EXPERIENCES_SUCCESS,
    payload,
  };
};

export const fetchExperiencesFailure = (payload: any): any => ({
  type: FETCH_EXPERIENCES_FAILURE,
  payload,
});

export const setExperiencesCategory = (payload: any): any => ({
  type: SET_EXPERIENCES_CATEGORY,
  payload,
});

export const setExperiencesSearch = (payload: any): any => ({
  type: SET_EXPERIENCES_SEARCH,
  payload,
});
