import { useEffect, useState } from 'react';
import { getProducts } from 'store/products/selectors';
import { useSelector } from 'react-redux';

const useStay = () => {
  let products = useSelector(getProducts);
  const [product] = products;
  const { location_tag } = product || {};
  const [locTag, setLocTag] = useState('');
  useEffect(() => {
    if (products && products.length > 0) {
      setLocTag(location_tag);
    }
  }, [location_tag, products]);

  return locTag;
};

export default useStay;
