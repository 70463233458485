import { useEffect, useState, useCallback } from 'react';
import { getCurrencies } from "../store/currencies/selectors"
import { useSelector } from 'react-redux';
import { getStays } from "../store/stays/selectors";

const useLocationCurrency = () => {
  const locationData = useSelector(getStays);
  const [currencyInfo, setCurrencyInfo] = useState<any>({
    name: "Canadian Dollar",
    abbr:"CAD",
    symbol:"$",
    status: "ACTIVE",
    created_at:"2022-08-22T16:03:04.870+00:00",
    updated_at:"2022-08-22T16:03:04.870+00:00"
  });
  
  const currencies = useSelector(getCurrencies);
  useEffect(() => {
   
    if (locationData?.currency)
    {
      currencies.map((item) => {
        if (item?.abbr === locationData?.currency)
        {
          setCurrencyInfo(item)
        }
      })
    }

  }, [currencies,locationData?.currency])
  return { currencyInfo }
};

export default useLocationCurrency;
