import React from 'react';
import { useDispatch } from 'react-redux';
import { faDeleteLeft, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { formatPrice } from 'utils';
import './styles.scss';
import DefaultImage from 'assets/images/default-image.png';
import { removeFromCart } from 'store/cart/actions';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Link } from 'react-router-dom';
import { faCircleXmark } from '@fortawesome/free-regular-svg-icons';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const faTimesIcon = faTimes as IconProp;

interface IProps {
  locationTag: string;
  item_id: string;
  quantity: number;
  title: string;
  price: number;
  editableQuantity: boolean;
  images: any;
  setDeleteLoading: any;
  tax: any;
  hasTax: boolean;
  symbol: any;
}

const CartItem = ({
  images,
  locationTag,
  item_id,
  quantity,
  title,
  price,
  editableQuantity,
  setDeleteLoading,
  tax,
  hasTax,
  symbol,
}: IProps) => {
  const dispatch = useDispatch();

  const handleRemove = (item_id: string, locationTag: string) => {
    setDeleteLoading(true);
    dispatch(removeFromCart({ item_id, locationTag }));
  };

  let rates = Object.keys(tax?.rates || {});

  return (
    <div className="cart_list__cart_product clearfix">
      {editableQuantity && (
        <FontAwesomeIcon
          icon={faCircleXmark}
          className="cart_list__close-button"
          onClick={() => handleRemove(item_id, locationTag)}
        />
      )}

      <div className="cart_list__product_info">
        <div>
          <img
            src={images[0] || DefaultImage}
            alt="product"
            className="cart_list__image-product"
          />
        </div>
        <div style={{ paddingLeft: 11 }}>
          <div style={{ fontSize: '16px', fontWeight: '500' }}>{title}</div>
          <div style={{ fontSize: '12px' }}>QTY: {quantity}</div>
        </div>
      </div>
      {/* // @ts-ignore */}

      <Container
        className="price_tax_item"
        style={{
          textAlign: hasTax ? 'center' : 'right',
        }}
      >
        <Row>
          <Col xs={hasTax ? 6 : 0}>
            <span className="cart_list__taxLabel">
              {rates.length ? (
                <div style={{ fontSize: '13px' }}>
                  (
                  {rates.map((tax, i) => (
                    <span key={i}>{(i ? ', ' : '') + tax.substring(0, 1)}</span>
                  ))}
                  )
                </div>
              ) : null}
            </span>
          </Col>
          <Col xs={hasTax ? 6 : 12} style={{ whiteSpace: 'nowrap' }}>
            <span className="price">
              {symbol}
              {formatPrice(price)}
            </span>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default CartItem;
