import React from 'react';
import { Container } from 'react-bootstrap';
import TopFooter from './TopFooter';
import { VERSION } from 'const';

import './styles.scss';

const Footer = () => {
  return (
    <div>
      <TopFooter />
      <div className="footer footer-terms">
        <a href="https://metaguest.ai/terms-of-service-waiver/">
          Terms Of Service
        </a>
        <a href="https://metaguest.ai/privacy-policy/">Privacy Policy</a>
      </div>
      <div className="footer  footer-copyright">
        <Container>
          <p className="footer__text">
            ©{new Date().getFullYear()} Metaguest Inc. {VERSION}
          </p>
        </Container>
      </div>
    </div>
  );
};

export default Footer;
