import React, { useEffect, useState } from 'react';
import BackButton from 'components/BackButton';
import CartList from '../CartList';
import CheckoutForm from '../CheckoutForm';

const OrderCheckout = ({ locationTag }: any) => {
  const [deleteLoading, setDeleteLoading] = useState(false);
  useEffect(() => {
    if (deleteLoading) {
      setDeleteLoading(false);
    }
  }, [deleteLoading]);

  return (
    <div className="checkout-session-container">
      <h3 className="header-title">YOUR ORDER</h3>
      <BackButton link={`/${locationTag}`} />
      <div className="row  pt-4">
        <div className="col-12 col-md-8">
          <CartList
            locationTag={locationTag}
            editableQuantity={true}
            setDeleteLoading={setDeleteLoading}
          />
        </div>
        <div className="col-12 col-md-4">
          {/* {!deleteLoading? :null} */}
          {!deleteLoading ? <CheckoutForm /> : null}
        </div>
      </div>
    </div>
  );
};

export default OrderCheckout;
