import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import DefaultImage from 'assets/images/default-image.png';
import UniversalButton from 'components/UniversalButton';
import axios from 'axiosInstance';
import parse from 'html-react-parser';
import Gallery from './Gallery';
import { useSelector } from 'react-redux';
import { getSettings } from 'store/stays/selectors';
import { gaServiceRequest } from 'utils/google_analytics';
import './styles.scss';

const ExpressCheckout = ({
  locationTag,
  _id,
  title,
  description,
  images,
  type,
}: any) => {
  const serviceInventory = type.toLocaleLowerCase() === 'service';
  const settings = useSelector(getSettings);
  const [requestResp, setRequestResp] = useState(false);
  const [error, setError] = useState({
    roomNumber: '',
    lastName: '',
  });
  const [roomNumber, setRoomNumber] = useState('');
  const [lastName, setLastName] = useState('');
  const [comments, setComments] = useState('');

  useEffect(() => {
    setRequestResp(false);
  }, [_id]);

  const handleValidation = () => {
    let valid = true;
    let errorObject = { roomNumber: '', lastName: '' };
    setError(errorObject);

    if (!roomNumber.trim() && settings.show_room_number) {
      errorObject = {
        ...errorObject,
        roomNumber: 'Please enter your room number.',
      };
      setError(errorObject);
      valid = false;
    }
    if (
      !lastName.trim() &&
      settings.show_last_name &&
      settings.show_room_number
    ) {
      errorObject = {
        ...errorObject,
        lastName: 'Please enter your last name.',
      };
      setError(errorObject);
      valid = false;
    }
    return valid;
  };

  const [pic] = images;
  const submitRequestHandler = async () => {
    let valid = handleValidation();
    if (!valid) {
      return;
    }

    const comment = lastName
      ? `${
          comments.trim() ? comments.trim() + ' ' : comments.trim()
        }Last Name: ${lastName}`
      : comments.trim();

    const data = {
      ...(roomNumber && { location_room_name: roomNumber }),
      comment,
      items: [{ id: _id, qty: 1 }],
    };

    try {
      await axios.post(`/locations/${locationTag}/transaction`, data);

      toast.success('Request is submitted successfully !', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });

      setRequestResp(true);
      gaServiceRequest(title, locationTag, _id);
      return {};
    } catch (e: any) {
      toast.error('Sorry Unable to send Request this time', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      return false;
    }
  };
  return (
    <div className="productSingle row">
      {serviceInventory && (
        <div className="col-sm-12 col-md-6 details">
          {requestResp ? (
            <div style={{ fontWeight: 'bold', color: 'green' }}>
              Your Request is Submitted Successfully
            </div>
          ) : (
            <>
              <h3 className="productSingle__name">{title}</h3>
              <div>
                {settings.show_room_number && (
                  <div className="service_room_number">
                    <div className="service_room_number_row">
                      <div className="service_room_number_label">Room #</div>
                      <input
                        className="service_room_number_input"
                        type="text"
                        value={roomNumber}
                        onChange={(e) => setRoomNumber(e.target.value)}
                      />
                    </div>
                    {error.roomNumber && (
                      <p className="error">{error.roomNumber}</p>
                    )}
                    {settings.show_last_name && (
                      <>
                        <div className="service_room_number_row">
                          <div className="service_room_number_label">
                            Last Name
                          </div>
                          <input
                            className="service_room_number_name"
                            type="text"
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                          />
                        </div>
                        {error.lastName && (
                          <p className="error">{error.lastName}</p>
                        )}
                      </>
                    )}
                  </div>
                )}
              </div>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <textarea
                  defaultValue={comments}
                  onChange={(e) => setComments(e.target.value)}
                  placeholder="Type your message"
                  style={{ borderColor: '#D3D3D3', padding: 10 }}
                  rows={6}
                />
              </div>

              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div style={{ paddingTop: 10 }}>
                  <UniversalButton
                    buttonTitle={'Checkout Now'}
                    onClickHandler={submitRequestHandler}
                  />
                </div>
              </div>
              {!requestResp && (
                <div className="productSingle__description">
                  {parse(description)}
                </div>
              )}
            </>
          )}
        </div>
      )}
      <div className="col-sm-12 col-md-6 text-center">
        <img
          src={pic || DefaultImage}
          alt="product"
          className="productSingle__image"
        />
        {images?.length > 1 && <Gallery pictures={images} />}
      </div>
    </div>
  );
};

export default ExpressCheckout;
